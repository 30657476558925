import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import 'highcharts/highcharts-more';
import 'highcharts/modules/dumbbell';
import { DISABLE_CHART_EXPORTING_MENU } from '@/constants/chartOptions';
import { RiskDecompositionChartDataset } from '@components/RisksDecompositions/helpers';

interface TimeLineChartProps {
  data: RiskDecompositionChartDataset;
  options?: Options;
}

const RisksDecompositionChart = ({ data, options = {} }: TimeLineChartProps) => {
  const chartOptions = {
    chart: {
      type: 'dumbbell',
      inverted: true,
      backgroundColor: 'transparent',
      height: 393,
    },
    title: {
      text: null,
    },
    tooltip: {
      formatter: function (): string {
        //@ts-expect-error this is issue with highcharts
        const point = this.point;

        if (point.comparedEntity.name) {
          return `
          <span style="font-weight: bold">${point.name}</span>
          <br/>
          <span style="font-weight: bold; color: #9388E8">${point.originalEntity.name}: </span><span>${point.originalEntity.value}</span>
          <br/>
          <span style="font-weight: bold">${point.comparedEntity.name}: </span><span>${point.comparedEntity.value}</span>
`;
        }

        return `
          <span style="font-weight: bold">${point.name}</span>
          <br/>
          <span style="font-weight: bold; color: #9388E8">${point.originalEntity.name}: </span><span>${point.originalEntity.value}</span>
          <br/>
`;
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: null,
      },
      opposite: true,
      gridLineColor: '#282829',
      labels: {
        formatter: function (): string {
          let color;
          //@ts-expect-error this is issue with highcharts
          if (this.value < 31) {
            color = '#B51C1C';
            //@ts-expect-error this is issue with highcharts
          } else if (this.value <= 71) {
            color = '#E5B302';
          } else {
            color = '#4DBA32';
          }
          //@ts-expect-error this is issue with highcharts
          return `<span style="color: ${color}; font-weight: 600">${this.value}</span>`;
        },
      },
    },
    xAxis: {
      type: 'category',
      labels: {
        enabled: false,
      },
      min: 0,
      max: 9,
      startOnTick: true,
      endOnTick: true,
      plotLines: [-0.5, 0.5, 1.5, 2.5, 3.5, 4.5, 5.5, 6.5, 7.5, 8.5, 9.5].map((i) => ({
        value: i,
        width: 1,
        color: '#282829',
      })),
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        connectorWidth: 5,
        marker: {
          radius: 5,
          states: {
            hover: {
              lineWidth: 0,
            },
          },
        },
      },
    },
    series: [
      {
        data: data.increase,
        label: null,
        lowColor: '#9388E8', // Example color for low marker
        highColor: '#FFFFFF', // Example color for high marker
        color: '#9388E8',
        lineWidth: 8,
        marker: {
          symbol: 'circle', // Define the shape of the marker
          fillColor: 'white', // Optional: Fill color of the marker
        },
        dataLabels: {
          enabled: true,
          color: '#9388E8',
          align: 'right',
          xLow: 40,
          xHigh: -5,
          inside: false,
          formatter: function (): string {
            //@ts-expect-error this is issue with highcharts
            const point = this.point;
            if (point.low === point.high) {
              if (!point.displayedLabel) {
                point.displayedLabel = true;
                // @ts-expect-error this is issue with highcharts
                return this.y.toString();
              }

              point.displayedLabel = false;
              return '';
            }
            //@ts-expect-error this is issue with highcharts
            if (this.point.low === this.y) {
              //@ts-expect-error this is issue with highcharts
              return '<span style="color: #9388E8;">' + this.y + '</span>';
            } else {
              //@ts-expect-error this is issue with highcharts
              return '<span style="color: #FFFFFF;">' + this.y + '</span>';
            }
          },
        },
      },
      {
        data: data.decrease,
        label: null,
        highColor: '#9388E8', // Example color for low marker
        lowColor: '#FFFFFF', // Example color for high marker
        color: '#FFFFFF',
        lineWidth: 8,
        marker: {
          symbol: 'circle', // Define the shape of the marker
          fillColor: '#9388E8', // Optional: Fill color of the marker
        },
        dataLabels: {
          enabled: true,
          color: '#9388E8',
          align: 'right',
          inside: false,
          xHigh: -5,
          xLow: 40,
          formatter: function (): string {
            //@ts-expect-error this is issue with highcharts
            const point = this.point;
            if (point.low === point.high) {
              if (!point.displayedLabel) {
                point.displayedLabel = true;
                //@ts-expect-error this is issue with highcharts
                return this.y.toString();
              }

              point.displayedLabel = false;
              return '';
            }
            //@ts-expect-error this is issue with highcharts
            if (this.point.low === this.y) {
              //@ts-expect-error this is issue with highcharts
              return '<span style="color: #FFFFFF;">' + this.y + '</span>';
            } else {
              //@ts-expect-error this is issue with highcharts
              return '<span style="color: #9388E8;">' + this.y + '</span>';
            }
          },
        },
      },
    ],
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default RisksDecompositionChart;
