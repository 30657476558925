import { useMemo } from 'react';
import { isAfter, parseISO, subDays } from 'date-fns';
import { trendingRiskChartOptions } from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/constants';
import { useTrendingRiskChartModeManager } from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/useTrendingRiskChartModeManager';
import { RiskZoneTrendingChart } from '@/components/molecules/Charts/RiskZoneTrendingChart';
import Select from '@/components/molecules/Select/Select';
import {
  StyledChartsWrapper,
  StyledChartWrapper,
  StyledScrollWrapper,
  StyledSelectWrapper,
  StyledSpan,
} from '@/views/EntityMonitoringView/components/TrendingRiskChart/TrendingRiskChart.styled';
import { IRiskZoneDTO } from '@/types/riskZone';

interface ITrendingRiskChartProps {
  riskZones: IRiskZoneDTO[];
}

export const TrendingRiskChart = ({ riskZones }: ITrendingRiskChartProps) => {
  const lastDayRiskZoneData = useMemo(() => {
    if (!riskZones.length) return riskZones;

    const lastDataTimestamp = parseISO(riskZones[0].timestamp);
    const previousDay = subDays(lastDataTimestamp, 1);

    return riskZones.filter((dataSet) => isAfter(parseISO(dataSet.timestamp), previousDay));
  }, [riskZones]);

  const { selectedTrendingRiskChart, handleTrendingRiskChartChange, isHourlyChart, generatedChartData } =
    useTrendingRiskChartModeManager({
      lastWeekRiskZoneData: riskZones,
      lastDayRiskZoneData: lastDayRiskZoneData,
    });

  const title = isHourlyChart ? '24hr Trending Risk' : '7days Trending Risk';

  if (!riskZones.length) return null;

  return (
    <StyledChartsWrapper>
      <StyledSpan>{title}</StyledSpan>
      <StyledScrollWrapper>
        <StyledChartWrapper>
          <StyledSelectWrapper>
            <Select
              options={trendingRiskChartOptions}
              onChange={handleTrendingRiskChartChange}
              value={selectedTrendingRiskChart}
              clearVisible={false}
            />
          </StyledSelectWrapper>
          <RiskZoneTrendingChart
            key={selectedTrendingRiskChart}
            chartData={generatedChartData}
            isHourlyChart={isHourlyChart}
          />
        </StyledChartWrapper>
      </StyledScrollWrapper>
    </StyledChartsWrapper>
  );
};
