import { AxiosError } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { ADMIN_ENTITY_IDS_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { handleCatch } from '@/api/helpers';
import {
  IEditEntityRequest,
  IGetEntitiesListRequest,
  IGetEntitiesListResponse,
} from '@/api/adminEntityIds/adminEntityIds.types';
import { IEntity, IEntityDto } from '@/types/entities';

export const useGetEntitiesListByAdmin = (payload: Partial<IGetEntitiesListRequest>) => {
  const { adminEntityIds } = useApi();

  const keys = [payload.page, payload.size].filter((e) => e).join(',');

  const {
    data: entitiesList,
    error,
    isLoading,
    refetch,
  } = useQuery<IGetEntitiesListResponse, Error>({
    queryKey: [ADMIN_ENTITY_IDS_KEY, 'GET_ENTITIES_LIST', keys],
    queryFn: () => {
      return adminEntityIds.getEntitiesList(payload).catch((error) => {
        return handleCatch(error, `load the entities list`);
      });
    },
    staleTime: 0,
  });

  return {
    entitiesList,
    error,
    isLoading,
    refetch,
  };
};

export const useGetUniqueEntitiesListByAdmin = ({
  isAuthenticated,
  isAdmin,
}: {
  isAuthenticated: boolean;
  isAdmin: boolean;
}) => {
  const { adminEntityIds } = useApi();

  const {
    data: entitiesListDisplayedToAdmin,
    error,
    isLoading,
  } = useQuery<IEntity[], Error>({
    queryKey: [ADMIN_ENTITY_IDS_KEY, 'GET_ALIVE'],
    queryFn: () => {
      return adminEntityIds.getUniqueEntitiesList().catch((error) => {
        return handleCatch(error, `load the admin entities list`);
      });
    },
    enabled: isAuthenticated && isAdmin,
  });

  return {
    entitiesListDisplayedToAdmin,
    error,
    isLoading,
  };
};

export const useGetEntityById = (entityId: string) => {
  const { adminEntityIds } = useApi();

  const {
    data: fetchedEntity,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IEntityDto, Error>({
    queryKey: [ADMIN_ENTITY_IDS_KEY, 'GET_ENTITY', entityId],
    meta: { entityId: entityId },
    queryFn: ({ meta }) => {
      if (meta && meta.entityId && typeof meta.entityId === 'string') {
        return adminEntityIds.getEntityById(meta.entityId).catch((reason) => {
          if (reason instanceof AxiosError && reason.response) {
            const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
            throw new Error(errorMessage);
          } else {
            return Promise.reject(new UnknownErrorAndWeCouldNot(`load the entity details`));
          }
        });
      }
      throw new Error('entityId should be a meta string');
    },
  });
  return {
    fetchedEntity,
    isLoading,
    isSuccess,
    error,
  };
};

export const useCreateEntity = () => {
  const { adminEntityIds } = useApi();

  const { error, mutate: createEntity } = useMutation<IEntityDto, Error, Partial<Omit<IEntityDto, 'id'>>>({
    mutationKey: [ADMIN_ENTITY_IDS_KEY, 'CREATE_ENTITY'],
    mutationFn: (payload: Partial<Omit<IEntityDto, 'id'>>) => {
      return adminEntityIds.createEntity(payload).catch((reason) => {
        return handleCatch(reason, `create new entity`);
      });
    },
  });

  return {
    error,
    createEntity,
  };
};

export const useEditEntity = () => {
  const { adminEntityIds } = useApi();

  const { error, mutate: editEntity } = useMutation<IEntityDto, Error, IEditEntityRequest>({
    mutationKey: [ADMIN_ENTITY_IDS_KEY, 'EDIT_ENTITY'],
    mutationFn: (payload: IEditEntityRequest) => {
      return adminEntityIds.editEntity(payload).catch((reason) => {
        return handleCatch(reason, `edit requested entity`);
      });
    },
  });

  return {
    error,
    editEntity,
  };
};
