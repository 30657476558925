import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetMonitoringDetails } from '@/api/monitoring/monitoring.hooks';
import Loader from '@/components/atoms/Loader/Loader';
import { EntityMonitoringView } from '@/views/EntityMonitoringView/EntityMonitoringView';
import { StyledLoaderContainer } from '@/views/EntityMonitoringView/EntityMonitoringView.styled';

export const EntityMonitoring = () => {
  const { entityName } = useParams();
  const { fetchedMonitoredEntityDetails, isLoading, error } = useGetMonitoringDetails(entityName || '');

  if (isLoading)
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );

  if (error) {
    throw error;
  }

  if (!fetchedMonitoredEntityDetails) {
    throw Error('Not found entity details data');
  }

  return <EntityMonitoringView fetchedData={fetchedMonitoredEntityDetails} />;
};
