import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@mui/material';
import Loader from '@/components/atoms/Loader/Loader';
import { NotificationsModeRadioGroup } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/NotificationsModeRadioGroup/NotificationsModeRadioGroup';
import { EntitiesToEmailNotification } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/EntitiesToEmailNotification/EntitiesToEmailNotification';
import { StyledWrapper } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm.styled';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

export interface IEntitiesEmailNotificationsFormProps {
  formMethods: UseFormReturn<IMonitoringForm>;
  sortedEntitiesByType: IExchange[];
  isGetUserEmailNotificationsSettingsLoading: boolean;
}

export const EntitiesEmailNotificationsForm = ({
  sortedEntitiesByType,
  formMethods,
  isGetUserEmailNotificationsSettingsLoading,
}: IEntitiesEmailNotificationsFormProps) => {
  const { notificationsMode } = formMethods.watch('emailNotifications');

  const generateFormContent = () => {
    if (isGetUserEmailNotificationsSettingsLoading) {
      return <Loader />;
    }

    return (
      <>
        <NotificationsModeRadioGroup formMethods={formMethods} />

        {notificationsMode === 'select' && (
          <EntitiesToEmailNotification formMethods={formMethods} sortedEntitiesByType={sortedEntitiesByType} />
        )}
      </>
    );
  };

  return (
    <StyledWrapper>
      <Typography variant="h2" color="white.100" fontWeight="semibold">
        Email Notifications
      </Typography>
      <Typography color="white.100" fontSize="16px">
        Choose entities to receive anomaly email notifications.
      </Typography>

      {generateFormContent()}
    </StyledWrapper>
  );
};
