import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { HttpClient } from '@/api/HttpClient';
import { IApiContext } from '@/providers/ApiContextProvider/ApiContext.types';
import { UserApi } from '@/api/user/user.api';
import { isTokenExpired } from '@/hooks/useUserIdFromToken';
import { AdminUserApi } from '@/api/adminUser/adminUser.api';
import { AdminAlertsApi } from '@/api/adminAlerts/adminAlerts.api';
import { EntityIdsApi } from '@/api/entityIds/entityIds.api';
import { AdminRatingsApi } from '@/api/adminRatings/adminRatings.api';
import { AdminRisksApi } from '@/api/adminRisks/adminRisks.api';
import { CalculatorsApi } from '@/api/calculators/calculators.api';
import { RisksApi } from '@/api/risks/risks.api';
import { RatingsApi } from '@/api/ratings/ratings.api';
import { DocumentationApi } from '@/api/documentation/documentation.api';
import { AdminEntityIdsApi } from '@/api/adminEntityIds/adminEntityIds.api';
import { MonitoringApi } from '@/api/monitoring/monitoring.api';

export const createApiClient = (accessToken: string, logOut: () => void): IApiContext => {
  const axiosClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  axiosClient.interceptors.request.use(
    (config) => {
      if (accessToken) {
        if (isTokenExpired(accessToken)) {
          logOut();
          window.location.reload();
        }
        config.headers['Authorization'] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // middleware converts camelCase to snake_case on request (outgoing),
  // and snake_case to camelCase on response (incoming)
  const axiosInstanceWithApplyCaseMiddleware = applyCaseMiddleware(axiosClient, {
    preservedKeys: [], // example if we want to skip conversion for particular keys
  });

  const httpClient = new HttpClient(axiosInstanceWithApplyCaseMiddleware);

  const apiClient: IApiContext = {
    userApi: new UserApi(httpClient),
    monitoringApi: new MonitoringApi(httpClient),
    adminUserApi: new AdminUserApi(httpClient),
    adminAlertsApi: new AdminAlertsApi(httpClient),
    adminEntityIds: new AdminEntityIdsApi(httpClient),
    adminRatingsApi: new AdminRatingsApi(httpClient),
    adminRisksApi: new AdminRisksApi(httpClient),
    entityIdsApi: new EntityIdsApi(httpClient),
    calculatorsApi: new CalculatorsApi(httpClient),
    risksApi: new RisksApi(httpClient),
    ratingsApi: new RatingsApi(httpClient),
    documentationApi: new DocumentationApi(httpClient),
  };

  return apiClient;
};
