import styled from '@emotion/styled';

export const StyledChartsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
`;

export const StyledSpan = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const StyledSelectWrapper = styled.div`
  align-self: flex-end;
  padding-top: 16px;
  width: 300px;
`;

export const StyledScrollWrapper = styled.div`
  overflow: auto;
`;

export const StyledChartWrapper = styled.div`
  min-width: 600px;
  display: flex;
  flex-direction: column;
`;
