import { useState } from 'react';
import { Box, Collapse, Table, TableBody, TableHead, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { format } from 'date-fns';
import { capitalizeFirstLetters, formatDateToAlert, formatNumberToEnUS, formatToUSD } from '@/helpers/helpers';
import { isChainKey } from '@/helpers/typeGuards';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { adjustAddressHref, adjustTrxHashHref } from '@/helpers/chainMethods';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import {
  StyledLink,
  StyledTableRow,
  StyledCell,
  StyledHeaderCell,
  StyledHeaderTableRow,
  StyledNestedTableCell,
} from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable.styled';
import { IAnomalyShortForMonitoringDto } from '@/types/anomaly';

interface IAnomalyTableRowProps {
  row: IAnomalyShortForMonitoringDto;
}

export const AnomalyTableRow = ({ row }: IAnomalyTableRowProps) => {
  const [isRowExpanded, setIsRowExpanded] = useState<boolean>(false);
  const isExpandableRow = row.counterParties !== null && !!row.counterParties.length;

  const handleExpandRow = () => {
    setIsRowExpanded((prev) => !prev);
  };

  return (
    <>
      <StyledTableRow key={row.id}>
        <StyledCell>
          {isExpandableRow && (
            <IconButton aria-label="expand row" size="small" onClick={handleExpandRow} color="inherit">
              {isRowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </StyledCell>

        <StyledCell component="th" scope="row">
          {row.severity ? <AlertSeverityBarsIcon severity={row.severity} /> : null}
        </StyledCell>
        <StyledCell>{formatDateToAlert(row.timestamp)}</StyledCell>
        <StyledCell>{mapMetadataType(row.metadata?.type)}</StyledCell>
        <StyledCell>
          {isExpandableRow && (
            <StyledLink underline="none" onClick={handleExpandRow}>
              <Typography color="white.100" fontSize="14px" display="flex" alignItems="center" gap="3px">
                Go to details <ArrowForwardIcon fontSize="small" />
              </Typography>
            </StyledLink>
          )}
        </StyledCell>
      </StyledTableRow>

      {row.counterParties !== null && !!row.counterParties.length && (
        <StyledTableRow isHoverDeactivated>
          <StyledNestedTableCell colSpan={5}>
            <Collapse in={isRowExpanded} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography color="white.100" fontSize="20px" gutterBottom>
                  Key Transaction History
                </Typography>
                <Table size="small" aria-label="transactions history">
                  <TableHead>
                    <StyledHeaderTableRow>
                      <StyledHeaderCell>Transaction Hash</StyledHeaderCell>
                      <StyledHeaderCell>From Address</StyledHeaderCell>
                      <StyledHeaderCell>To Address</StyledHeaderCell>
                      <StyledHeaderCell>Timestamp</StyledHeaderCell>
                      <StyledHeaderCell>Token</StyledHeaderCell>
                      <StyledHeaderCell>Token Amount</StyledHeaderCell>
                      <StyledHeaderCell>USD Amount</StyledHeaderCell>
                    </StyledHeaderTableRow>
                  </TableHead>
                  <TableBody>
                    {row.counterParties.map((data) => {
                      //if the type is incorrect, render ethereum href
                      const chain = isChainKey(data.chain) ? data.chain : 'ethereum';
                      const trxHashHref = adjustTrxHashHref(chain, data.trxHash);
                      const fromAddressHref = adjustAddressHref(chain, data.fromAddress);
                      const toAddressHref = adjustAddressHref(chain, data.toAddress);

                      return (
                        <StyledTableRow key={data.type}>
                          <StyledCell component="th" scope="row">
                            <StyledLink href={trxHashHref} underline="none" target="_blank">
                              <Typography noWrap width="150px" fontSize="14px">
                                {data.trxHash}
                              </Typography>
                            </StyledLink>
                          </StyledCell>
                          <StyledCell>
                            <StyledLink href={fromAddressHref} underline="none" target="_blank">
                              <Typography noWrap fontSize="14px">
                                {capitalizeFirstLetters(data.fromEntity)}
                              </Typography>
                            </StyledLink>
                          </StyledCell>
                          <StyledCell>
                            <StyledLink href={toAddressHref} underline="none" target="_blank">
                              <Typography noWrap fontSize="14px">
                                {capitalizeFirstLetters(data.toEntity)}
                              </Typography>
                            </StyledLink>
                          </StyledCell>
                          <StyledCell>
                            <Typography noWrap fontSize="14px">
                              {format(new Date(data.timestamp), 'dd MMM, yyyy HH:mm:ss')} UTC
                            </Typography>
                          </StyledCell>
                          <StyledCell>
                            <Typography noWrap fontSize="14px">
                              {data.token}
                            </Typography>
                          </StyledCell>
                          <StyledCell>
                            <Typography noWrap fontSize="14px">
                              {formatNumberToEnUS(data.nativeUnits)}
                            </Typography>
                          </StyledCell>
                          <StyledCell>
                            <Typography noWrap fontSize="14px">
                              {formatToUSD(data.usdSize)}
                            </Typography>
                          </StyledCell>
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </StyledNestedTableCell>
        </StyledTableRow>
      )}
    </>
  );
};
