import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { MONITORING_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import {
  IGetMonitoringRequest,
  IGetMonitoringDetailsResponse,
  IMonitoredEntity,
} from '@/api/monitoring/monitoring.types';

export const useGetMonitoring = ({ entityType, allHighAndExtreme }: IGetMonitoringRequest) => {
  const { monitoringApi } = useApi();

  const {
    data: fetchedMonitoredEntities,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IMonitoredEntity[], Error>({
    queryKey: [MONITORING_KEY, 'GET', entityType, allHighAndExtreme],
    refetchInterval: 3600000, // 1hour
    queryFn: () => {
      return monitoringApi
        .getMonitoring({ entityType, allHighAndExtreme })
        .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load monitoring data`)));
    },
  });

  return {
    fetchedMonitoredEntities,
    isLoading,
    isSuccess,
    error,
  };
};

export const useGetMonitoringDetails = (entityId: string) => {
  const { monitoringApi } = useApi();

  const {
    data: fetchedMonitoredEntityDetails,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IGetMonitoringDetailsResponse, Error>({
    queryKey: [MONITORING_KEY, 'GET_MONITORED_ENTITY_DETAILS', entityId],
    meta: { entityId: entityId },
    refetchInterval: 3600000, // 1hour
    queryFn: ({ meta }) => {
      if (meta && meta.entityId && typeof meta.entityId === 'string') {
        return monitoringApi
          .getMonitoringDetails(meta.entityId)
          .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load monitored entity details data`)));
      }
      throw new Error('entityId should be a meta string');
    },
  });

  return {
    fetchedMonitoredEntityDetails,
    isLoading,
    isSuccess,
    error,
  };
};
