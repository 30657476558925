import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useBlockNavigation = (shouldBlock: boolean, message: string) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    if (shouldBlock) {
      const confirmLeave = window.confirm(message);
      if (confirmLeave) {
        navigate(path);
      }
      return;
    }

    navigate(path);
  };

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldBlock) {
        event.preventDefault();
        return message;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldBlock, message]);

  return { handleNavigation };
};
