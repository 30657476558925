import React from 'react';
import { Controller } from 'react-hook-form';
import {
  handleGroupBy,
  handleOnChange,
  handleSelectOption,
} from '@/views/MonitoringSettingsView/utils/autocompleteHelpers';
import { AutocompleteGroup } from '@/components/AutocompleteGroup/AutocompleteGroup';
import { AutocompleteOptionWithCheckbox } from '@/components/AutocompleteOptionWithCheckbox/AutocompleteOptionWithCheckbox';
import { MonitorEntitiesInput } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/components/MonitorEntitiesInput/MonitorEntitiesInput';
import { AutocompleteTags } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/components/AutocompleteTags/AutocompleteTags';
import {
  StyledAutocomplete,
  StyledPaper,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/MoniorEntitiesAutocomplete.styled';
import { IEntitiesMonitoringFormProps } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

interface IMonitorEntitiesAutocompleteProps extends Pick<IEntitiesMonitoringFormProps, 'formMethods'> {
  sortedEntitiesList: IExchange[];
}

export const MonitorEntitiesAutocomplete = ({ formMethods, sortedEntitiesList }: IMonitorEntitiesAutocompleteProps) => {
  return (
    <Controller
      render={({ field: { value, onBlur, onChange, ref }, formState: { errors } }) => {
        return (
          <StyledAutocomplete
            multiple
            id="entities-list-to-monitor"
            disableCloseOnSelect
            disablePortal
            options={sortedEntitiesList}
            groupBy={handleGroupBy}
            renderInput={(params) => <MonitorEntitiesInput params={params} errors={errors} fieldRef={ref} />}
            value={value}
            PaperComponent={StyledPaper}
            renderGroup={(params) => <AutocompleteGroup params={params} />}
            renderOption={(props, option, { selected }) => (
              <AutocompleteOptionWithCheckbox props={props} option={option} selected={selected} />
            )}
            renderTags={(values) => <AutocompleteTags values={values} formMethods={formMethods} />}
            isOptionEqualToValue={handleSelectOption}
            onChange={(event, newValues) => handleOnChange(newValues, onChange)}
            onBlur={onBlur}
          />
        );
      }}
      name="entities"
      control={formMethods.control}
    />
  );
};
