import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IAdminUserApi,
  ICreateUserRequest,
  IEditUserAsAdminRequest,
  IGetUserListRequest,
  IGetUserListResponse,
} from '@/api/adminUser/adminUser.types';
import { IUserDto } from '@/types/user';

export class AdminUserApi implements IAdminUserApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_USER;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getUserList({ page, size, cohort, login }: Partial<IGetUserListRequest>) {
    const params = {
      page,
      size,
      cohort,
      login,
    };
    return this.httpClient.get<IGetUserListResponse>(this.getBaseUrl(), { params });
  }

  getUserById(id: string) {
    return this.httpClient.get<IUserDto>(`${this.getBaseUrl()}${id}/`);
  }

  createUser(payload: ICreateUserRequest) {
    return this.httpClient.post<ICreateUserRequest, IUserDto>(this.getBaseUrl(), payload);
  }

  editUser({ id, ...payload }: IEditUserAsAdminRequest) {
    return this.httpClient.patch<Partial<IEditUserAsAdminRequest>, IUserDto>(`${this.getBaseUrl()}${id}/`, payload);
  }

  deleteUser(id: string) {
    return this.httpClient.delete<void>(`${this.getBaseUrl()}${id}/`);
  }

  resetUserPassword(id: string) {
    return this.httpClient.patch<void, IUserDto>(`${this.getBaseUrl()}${id}/reset_password/`);
  }
}
