import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@mui/material';
import { useMonitoringForm } from '@/views/MonitoringSettingsView/utils/useMonitoringForm';
import { useEntityOptionsManager } from '@/views/MonitoringSettingsView/utils/useEntityOptionsManager';
import Button from '@/components/atoms/Button/Button';
import { EntitiesMonitoringForm } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';
import { EntitiesEmailNotificationsForm } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';
import {
  StyledContainer,
  StyledForm,
  StyledButtonContainer,
} from '@/views/MonitoringSettingsView/MonitoringSettingsView.styled';

export const MonitoringSettingsView = () => {
  const {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isCreateMonitoringSettingsLoading,
    isGetMonitoringSettingsLoading,
    isEditUserEmailNotificationsSettingsLoading,
    isGetUserEmailNotificationsSettingsLoading,
  } = useMonitoringForm();
  const { listOfEntityTypesToDisplay, sortedEntitiesByType } = useEntityOptionsManager();
  return (
    <StyledContainer>
      <FormProvider {...monitoringMethods}>
        <StyledForm onSubmit={handleMonitoringFormSubmit}>
          <EntitiesMonitoringForm
            listOfEntityTypesToDisplay={listOfEntityTypesToDisplay}
            sortedEntitiesByType={sortedEntitiesByType}
            formMethods={monitoringMethods}
            isGetMonitoringSettingsLoading={isGetMonitoringSettingsLoading}
          />

          <EntitiesEmailNotificationsForm
            sortedEntitiesByType={sortedEntitiesByType}
            formMethods={monitoringMethods}
            isGetUserEmailNotificationsSettingsLoading={isGetUserEmailNotificationsSettingsLoading}
          />

          <StyledButtonContainer>
            <Button
              type="submit"
              variant="contained"
              fitContentWidth
              sx={{ minWidth: '142px' }}
              disabled={isCreateMonitoringSettingsLoading || isEditUserEmailNotificationsSettingsLoading}
            >
              Save
            </Button>
          </StyledButtonContainer>
        </StyledForm>
      </FormProvider>

      <Typography color="white.100" align="center" fontStyle="italic" m="10px auto">
        If you&apos;re interested in tracking something not on the current list, just{' '}
        <Link href="mailto:alerts@agioratings.io" underline="hover">
          send us
        </Link>{' '}
        a request, and we&apos;ll be happy to assist!
      </Typography>
    </StyledContainer>
  );
};
