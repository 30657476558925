import React from 'react';
import { FieldErrors, RefCallBack } from 'react-hook-form';
import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';

interface IMonitorEntitiesInputProps {
  params: AutocompleteRenderInputParams;
  errors: FieldErrors<IMonitoringForm>;
  fieldRef: RefCallBack;
}

export const MonitorEntitiesInput = ({ params, errors, fieldRef }: IMonitorEntitiesInputProps) => {
  return (
    <TextField
      {...params}
      label="Select Entities"
      error={!!errors.entities}
      helperText={errors.entities?.message}
      inputRef={fieldRef}
    />
  );
};
