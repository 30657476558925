import { useNavigate, useParams } from 'react-router-dom';
import { isAfter, parseISO, subDays } from 'date-fns';
import { Typography } from '@mui/material';
import { GridColDef, GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useTracking } from '@/hooks/useTracking';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useSortingManager } from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/useSortingManager';
import { mapRiskZoneChartData } from '@/helpers/mapChartsData';
import { formatToUSD } from '@/helpers/helpers';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { RiskZoneBarsIcon } from '@/components/RiskZoneBarsIcon/RiskZoneBarsIcon';
import { RiskZoneTrend } from '@/views/MonitoringDashboardView/components/RiskZoneTrend/RiskZoneTrend';
import {
  StyledDataGrid,
  StyledLogoWrapper,
} from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/MonitoredEntitiesTable.styled';
import { IMonitoredEntity } from '@/api/monitoring/monitoring.types';
import { ChartData } from '@/types/chartPoint';
import { ERoutes } from '@/types/routes';

interface IMonitoredEntitiesTableProps {
  monitoredEntities: IMonitoredEntity[];
}

export const MonitoredEntitiesTable = ({ monitoredEntities }: IMonitoredEntitiesTableProps) => {
  const { dashboardType } = useParams();
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();
  const { sortModel, updateSortModel, riskZoneComparator } = useSortingManager();

  if (!monitoredEntities.length && dashboardType === 'peak') {
    return (
      <>
        <Typography color="white.100" fontSize="24px" fontWeight="bold" align="center">
          Great news!
        </Typography>
        <Typography color="white.100" align="center">
          No active <strong>HIGH</strong> or <strong>EXTREME</strong> severity levels detected among the monitored
          entities.
        </Typography>
      </>
    );
  }

  const mappedEntities = monitoredEntities.map((entity) => {
    const areRiskZonesProvided = entity.riskZones.length;

    const latestRiskZone = areRiskZonesProvided ? { riskZone: entity.riskZones[0].value } : { riskZone: null };
    const latestBalance = entity.latestBalance?.value || null;
    const tag = entity.severity.description;

    let chartData: ChartData = [];

    if (areRiskZonesProvided) {
      const lastRiskZoneTimestamp = parseISO(entity.riskZones[0].timestamp);
      const previousDay = subDays(lastRiskZoneTimestamp, 1);

      const latestRiskZoneTrendFromTheLastDay = entity.riskZones.filter((dataSet) =>
        isAfter(parseISO(dataSet.timestamp), previousDay),
      );

      chartData = mapRiskZoneChartData(latestRiskZoneTrendFromTheLastDay);
    }

    return {
      ...entity,
      riskZone: latestRiskZone.riskZone,
      chartData,
      latestBalance,
      tag,
    };
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const href = params.row.displayName
      ? `${ERoutes.MONITORING_ENTITY}/${getExchangeLabelByDisplayName(params.row.displayName)}`
      : '';

    trackEvent('Monitoring - details click', {
      entity: params.row.displayName || 'unknown',
    });

    navigate(href);
  };

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Company',
      headerAlign: 'left',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledLogoWrapper>
            <ExchangeLogo companyName={getExchangeLabelByDisplayName(params.row.displayName)} height="50px" />
            {params.row.displayName}
          </StyledLogoWrapper>
        );
      },
    },
    {
      field: 'riskZone',
      headerName: 'Risk Zone',
      headerAlign: 'center',
      align: 'center',
      sortComparator: (v1, v2) => riskZoneComparator(v1, v2),
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const riskZone = params.row.riskZone;

        if (!riskZone) return null;

        return <RiskZoneBarsIcon riskZone={riskZone} />;
      },
    },
    {
      field: 'tag',
      headerName: 'Tag',
      headerAlign: 'center',
      align: 'left',
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const tag = params.row.tag;

        if (tag === null) return null;

        return (
          <Typography color="white.100" fontSize="14px" noWrap>
            {tag}
          </Typography>
        );
      },
    },
    {
      field: 'latestBalance',
      headerName: 'Latest Balance',
      headerAlign: 'right',
      align: 'right',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.latestBalance;

        if (!value) return null;

        return (
          <Typography color="white.100" fontSize="14px" align="right">
            {formatToUSD(value, true)}
          </Typography>
        );
      },
    },
    {
      field: 'chartData',
      headerName: 'Trending Risk Chart',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.chartData.length) return null;

        return <RiskZoneTrend chartData={params.row.chartData} />;
      },
    },
  ];

  return (
    <StyledDataGrid
      style={{ color: '#fff' }}
      rows={mappedEntities}
      getRowId={(params) => params.agioId}
      onRowClick={handleRowClick}
      columns={columns}
      hideFooter
      sortModel={sortModel}
      onSortModelChange={updateSortModel}
    />
  );
};
