import Loader from '@/components/atoms/Loader/Loader';
import { GeneratedResultView } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';

export const ResultOfRiskSimulation = () => {
  const { isSimulationLoading, resultOfRiskSimulation, parametersOfDisplayedSimulation } = useRiskSimulator();

  if (isSimulationLoading) return <Loader />;

  if (resultOfRiskSimulation !== null && parametersOfDisplayedSimulation !== null) {
    return <GeneratedResultView />;
  }

  return null;
};
