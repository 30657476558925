import { useMemo } from 'react';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

export const useEntityOptionsManager = () => {
  const { exchanges, listOfEntityTypes } = useEntitiesList();

  const listOfEntityTypesToDisplay = useMemo(() => {
    return listOfEntityTypes.filter(
      (type) => type !== 'defi' && type !== 'gambling' && type !== 'misc' && type !== 'infrastructure',
    );
  }, [listOfEntityTypes]);

  const sortedEntitiesByType = useMemo(() => {
    if (!exchanges.length || !listOfEntityTypesToDisplay.length) return [];

    const sortedBucket: IExchange[] = [];

    listOfEntityTypesToDisplay.forEach((type, i) => {
      exchanges.forEach((exchange) => {
        if (exchange.type === type) {
          sortedBucket.push(exchange);
        }
      });
    });

    return sortedBucket;
  }, [listOfEntityTypesToDisplay, exchanges]);

  return {
    listOfEntityTypesToDisplay,
    sortedEntitiesByType,
  };
};
