import { ESettingsNotifications, ICustomNotification, INotification } from '@/types/notifications';

export const notificationsInitialState: INotification[] = [
  { name: ESettingsNotifications.ANOMALIES, enabled: false },
  { name: ESettingsNotifications.NEWSLETTER, enabled: false },
  { name: ESettingsNotifications.UPDATES, enabled: false },
  { name: ESettingsNotifications.STATISTICAL_RATINGS, enabled: false },
];

export const betaUserNotifications: INotification[] = [
  { name: ESettingsNotifications.ANOMALIES, enabled: true },
  { name: ESettingsNotifications.NEWSLETTER, enabled: true },
  { name: ESettingsNotifications.UPDATES, enabled: true },
  { name: ESettingsNotifications.STATISTICAL_RATINGS, enabled: true },
];

export const initialCustomNotification: ICustomNotification = {
  text: '',
  type: '',
};
