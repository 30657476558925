import { isObjectWithKey } from '@/helpers/typeGuards';

export const handleGroupBy = (option: unknown) => {
  if (isObjectWithKey(option, 'type')) {
    return option.type;
  }
};

export const handleSelectOption = (option: unknown, value: unknown) => {
  if (isObjectWithKey(option, 'label')) {
    return option.label === value;
  }
  return false;
};

export const handleOnChange = (newValues: unknown, formOnChange: (...event: any[]) => void) => {
  if (Array.isArray(newValues)) {
    const extractedNewValues = [...newValues].map((val) => {
      if (typeof val === 'string') {
        return val;
      }
      return val.label;
    });
    formOnChange(extractedNewValues);
  }
};
