import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTracking } from '@/hooks/useTracking';
import { useGetMonitoring } from '@/api/monitoring/monitoring.hooks';
import { MonitoredEntitiesTable } from '@/views/MonitoringDashboardView/components/MonitoredEntitiesTable/MonitoredEntitiesTable';
import Loader from '@/components/atoms/Loader/Loader';
import {
  StyledContainer,
  StyledLoaderContainer,
  StyledButton,
  StyledLink,
} from '@/views/MonitoringDashboardView/MonitoringDashboardView.styled';
import { ERoutes } from '@/types/routes';

export const MonitoringDashboardView = () => {
  const { dashboardType } = useParams();
  const isCustomDashboard = dashboardType === 'custom';

  const payload = useMemo(() => {
    const defaultDashboardPayload = { entityType: null, allHighAndExtreme: null };

    if (!dashboardType) return defaultDashboardPayload;

    const entityTypeDashboardPayload = { entityType: dashboardType, allHighAndExtreme: null };

    switch (dashboardType) {
      case 'cex':
        return entityTypeDashboardPayload;
      case 'custody':
        return entityTypeDashboardPayload;
      case 'fund':
        return entityTypeDashboardPayload;
      case 'stablecoin_issuer':
        return entityTypeDashboardPayload;
      case 'custom':
        return defaultDashboardPayload;
      case 'peak':
        return { entityType: null, allHighAndExtreme: true };
      default:
        return defaultDashboardPayload;
    }
  }, [dashboardType]);

  const { fetchedMonitoredEntities, isLoading, error } = useGetMonitoring(payload);
  const { trackEvent } = useTracking();

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (error || !fetchedMonitoredEntities) {
    throw error;
  }

  return (
    <StyledContainer>
      <MonitoredEntitiesTable monitoredEntities={fetchedMonitoredEntities} />
      {isCustomDashboard && (
        <StyledLink
          href={ERoutes.MONITORING_SETTINGS}
          underline="none"
          onClick={() => trackEvent('Monitoring - click settings', {})}
        >
          <StyledButton variant="outlined" color="tertiary" skipTypography fitContentWidth>
            <AddCircleOutlineIcon />
            Add entity
          </StyledButton>
        </StyledLink>
      )}
    </StyledContainer>
  );
};
