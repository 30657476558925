import styled from '@emotion/styled';
import { Chip } from '@mui/material';

export const StyledChip = styled(Chip)<{
  isDragging: boolean;
  transform: React.CSSProperties['transform'] | null;
  transition: React.CSSProperties['transition'];
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  height: 30px;
  background-color: ${({ isDragging }) => (isDragging ? '#5d51b8' : '#222')};
  opacity: ${({ isDragging }) => (isDragging ? 0.8 : 1)};
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'pointer')};
  z-index: ${({ isDragging }) => (isDragging ? 10 : 'auto')};
  transform: ${({ transform }) => transform};
  transition: ${({ transition }) => transition};
`;

export const StyledDeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  border: none;
  width: auto;
  height: auto;
  background-color: transparent;
`;
