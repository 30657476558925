import { useParams, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useGetAlertByIdByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import {
  StyledInput,
  StyledText,
  StyledUpdatesContainer,
  StyledWrapperInput,
  StyledWrapperButton,
} from './AdminPanelUpdateDetails.styled';
import { EAdminPaths, ERoutes } from '@/types/routes';

const AdminPanelUpdateDetails = () => {
  const { id, updateID } = useParams<{ id: string; updateID: string }>();
  const navigate = useNavigate();
  const { fetchedAlert, isLoading, error } = useGetAlertByIdByAdmin({ alertId: id || '' });

  const handleCancel = () => {
    navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}/${id}`);
  };

  if (isLoading) {
    return <Typography color="white.100">Loading alert details...</Typography>;
  }

  if (!fetchedAlert || !fetchedAlert.updates || error) {
    return <Typography color="white.100">{error?.message || 'There is no alert details.'}</Typography>;
  }

  const alertUpdate = fetchedAlert.updates.find((update) => update.id === updateID);

  if (!alertUpdate) {
    return <Typography color="white.100">{'There is no found requested alert update.'}</Typography>;
  }

  return (
    <>
      {alertUpdate && !alertUpdate.deleted && (
        <StyledUpdatesContainer>
          <StyledWrapperInput>
            <StyledText fontSize="20px">Date of update</StyledText>
            <StyledInput borderColor="#fff" type="date" value={alertUpdate.timestamp} disabled />
          </StyledWrapperInput>
          <StyledWrapperInput>
            <StyledText fontSize="20px">Title</StyledText>
            <StyledInput borderColor="#fff" value={alertUpdate.title} disabled />
          </StyledWrapperInput>
          <StyledWrapperInput width="100%">
            <StyledText fontSize="20px">Details</StyledText>

            <StyledInput value={alertUpdate.details} disabled />
          </StyledWrapperInput>
          <StyledWrapperInput width="100%">
            <StyledWrapperButton>
              <AdminButton onClick={handleCancel}>Go back</AdminButton>
            </StyledWrapperButton>
          </StyledWrapperInput>
        </StyledUpdatesContainer>
      )}
    </>
  );
};

export default AdminPanelUpdateDetails;
