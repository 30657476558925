import { FormProvider } from 'react-hook-form';
import { useRiskSimulator } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { CounterpartyExposures } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/CounterpartyExposures';
import { ContagionLevel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/ContagionLevel/ContagionLevel';
import { SimulatorPanel } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/SimulatorPanel/SimulatorPanel';
import { StyledSimulatorWrapper } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator.styled';

interface ISimulatorProps {
  onFormSubmit: () => void;
}

export const Simulator = ({ onFormSubmit }: ISimulatorProps) => {
  const { riskSimulatorMethods, handleRiskSimulatorFormSubmit } = useRiskSimulator();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleRiskSimulatorFormSubmit();
    onFormSubmit();
  };

  return (
    <FormProvider {...riskSimulatorMethods}>
      <form onSubmit={handleFormSubmit}>
        <StyledSimulatorWrapper>
          <CounterpartyExposures />

          <ContagionLevel />

          <SimulatorPanel />
        </StyledSimulatorWrapper>
      </form>
    </FormProvider>
  );
};
