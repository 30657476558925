import React from 'react';
import { FieldErrors, RefCallBack } from 'react-hook-form';
import { AutocompleteRenderInputParams, TextField } from '@mui/material';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';

interface IEntitiesToEmailNotificationInputProps {
  params: AutocompleteRenderInputParams;
  errors: FieldErrors<IMonitoringForm>;
  fieldRef: RefCallBack;
}

export const EntitiesToEmailNotificationInput = ({
  params,
  errors,
  fieldRef,
}: IEntitiesToEmailNotificationInputProps) => {
  return (
    <TextField
      {...params}
      label="Select Entities"
      error={!!errors.emailNotifications?.selectedEntities}
      helperText={errors.emailNotifications?.selectedEntities?.message}
      inputRef={fieldRef}
    />
  );
};
