import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetLossCalculations } from '@/api/calculators/calculators.hooks';
import { formatFloatToPercent, formatToUSD } from '@/helpers/helpers';
import {
  mapDataDefaultsFrequencyToTransformedDataFrequency,
  mapDataLossFrequencyToTransformedDataFrequency,
} from '@/views/RiskSimulatorPortfolioView/utils/helpers';
import { defaultRiskSimulatorFormValues } from '@/views/RiskSimulatorPortfolioView/utils/constants';
import { RiskSimulatorSchema } from '@/views/RiskSimulatorPortfolioView/utils/validationSchema';
import {
  IRiskSimulatorForm,
  IResultOfRiskSimulation,
  IParametersOfSimulation,
} from '@/views/RiskSimulatorPortfolioView/utils/types';
import { IGetLossCalculationsRequest, IGetLossCalculationsResponse } from '@/api/calculators/calculators.types';

export const useRiskSimulatorForm = () => {
  const riskSimulatorMethods = useForm<IRiskSimulatorForm>({
    defaultValues: defaultRiskSimulatorFormValues,
    resolver: zodResolver(RiskSimulatorSchema),
  });

  const { handleSubmit } = riskSimulatorMethods;

  const { getLossCalculations, isPending } = useGetLossCalculations();

  const [resultOfRiskSimulation, setResultOfRiskSimulation] = useState<IResultOfRiskSimulation | null>(null);

  const clearResultsOfRiskSimulation = () => setResultOfRiskSimulation(null);

  const [parametersOfDisplayedSimulation, setParametersOfDisplayedSimulation] =
    useState<IParametersOfSimulation | null>(null);

  const clearParametersOfParametersOfSimulation = () => setParametersOfDisplayedSimulation(null);

  const onCalculateLossSubmit = useCallback(
    async (data: IRiskSimulatorForm) => {
      const payload: IGetLossCalculationsRequest = {
        correlation: data.contagionLevel.correlation,
        entities: data.rows.map((r) => ({
          agioId: r.entity,
          probDefault: r.pd / 100,
          recoveryPct: r.recovery / 100,
          exposureUsd: r.exposure,
        })),
      };

      getLossCalculations(payload, {
        onSuccess: (response: IGetLossCalculationsResponse) => {
          setResultOfRiskSimulation({
            totalExposure: formatToUSD(response.totalExposure),
            expectedLossUsd: formatToUSD(response.expectedLossUsd),
            expectedLossPct: formatFloatToPercent(response.expectedLossPct),
            zeroDefaults: formatFloatToPercent(response.zeroDefaults),
            probExceedingLoss: response.probExceedingLoss
              ? formatFloatToPercent(response.probExceedingLoss)
              : undefined,
            creditVar: response.creditVar ? formatFloatToPercent(response.creditVar) : undefined,
            creditVar950Pct: response.creditVar_950 ? formatFloatToPercent(response.creditVar_950.pct) : undefined,
            creditVar990Pct: response.creditVar_990 ? formatFloatToPercent(response.creditVar_990.pct) : undefined,
            creditVar999Pct: response.creditVar_999 ? formatFloatToPercent(response.creditVar_999.pct) : undefined,
            creditVar950Usd: response.creditVar_950 ? formatToUSD(response.creditVar_950.usd) : undefined,
            creditVar990Usd: response.creditVar_990 ? formatToUSD(response.creditVar_990.usd) : undefined,
            creditVar999Usd: response.creditVar_999 ? formatToUSD(response.creditVar_999.usd) : undefined,
            defaultsFrequency: response.defaultsFrequency
              ? mapDataDefaultsFrequencyToTransformedDataFrequency(response.defaultsFrequency)
              : undefined,
            lossFrequency: response.lossFrequency
              ? mapDataLossFrequencyToTransformedDataFrequency(response.lossFrequency)
              : undefined,
          });

          setParametersOfDisplayedSimulation({
            correlation: data.contagionLevel.correlation,
            contagionLevel: data.contagionLevel.name,
            numberOfExchanges: data.rows.length,
            rows: data.rows,
            pdSelectedDate: data.pdSelectedDate,
          });
        },
        onError: (error) => {
          clearResultsOfRiskSimulation();
          clearParametersOfParametersOfSimulation();
          console.error(error);
        },
      });
    },
    [getLossCalculations],
  );

  const handleRiskSimulatorFormSubmit = handleSubmit(onCalculateLossSubmit);

  return {
    riskSimulatorMethods,
    handleRiskSimulatorFormSubmit,
    resultOfRiskSimulation,
    clearResultsOfRiskSimulation,
    isSimulationLoading: isPending,
    parametersOfDisplayedSimulation,
  };
};
