import { PropsWithChildren } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ERoutes } from '@/types/routes';
import { isMonitoringDashboardTypeKey } from '@/types/dashboardTypes';

export const DashboardTypeRoute = ({ children }: PropsWithChildren) => {
  const { dashboardType } = useParams();

  if (dashboardType && isMonitoringDashboardTypeKey(dashboardType)) {
    return <>{children}</>;
  }

  return <Navigate to={`${ERoutes.MONITORING}/custom`} replace />;
};
