import { variables } from '@/components/RisksDecompositions/constants';

export type RiskDecompositionDataPoint = {
  [key: string]: any;
};

interface IEntityData {
  name?: string;
  value: number;
}

interface IChartDataset {
  name: string;
  low: number;
  high: number;
  x: number;
  originalEntity?: IEntityData;
  comparedEntity?: IEntityData;
}

export interface RiskDecompositionChartDataset {
  increase: IChartDataset[];
  decrease: IChartDataset[];
}

export function prepareChartData(
  data: RiskDecompositionDataPoint,
  comparedData?: null | RiskDecompositionDataPoint,
  originalEntityName?: string,
  comparedEntityName?: string,
): RiskDecompositionChartDataset {
  const increase = [];
  const decrease = [];

  for (const [index, v] of variables.entries()) {
    if (v.key) {
      if (comparedData) {
        if (data[v.key] > comparedData[v.key]) {
          increase.push({
            name: v.name,
            x: index + 0.15,
            low: data[v.key],
            high: comparedData[v.key],
            originalEntity: { name: originalEntityName, value: data[v.key] },
            comparedEntity: { name: comparedEntityName, value: comparedData[v.key] },
          });
        } else {
          decrease.push({
            name: v.name,
            x: index - 0.15,
            low: comparedData[v.key],
            high: data[v.key],
            originalEntity: { name: originalEntityName, value: data[v.key] },
            comparedEntity: { name: comparedEntityName, value: comparedData[v.key] },
          });
        }
      } else {
        increase.push({
          name: v.name,
          x: index + 0.15,
          low: data[v.key],
          high: data[v.key],
          originalEntity: { name: originalEntityName, value: data[v.key] },
          comparedEntity: { name: comparedEntityName, value: data[v.key] },
        });
      }
    }
  }
  return {
    increase,
    decrease,
  };
}

export function compareRisksDates(selectedDate: string, riskDate: string) {
  return selectedDate === riskDate.split('T')[0];
}

export function mapEntityNameToRisksDecomposition(entityName?: string) {
  switch (entityName) {
    case 'gdax':
      return 'coinbase';
    case 'gate':
      return 'gate_io';
    case 'bybit_spot':
      return 'bybit';
    default:
      return entityName;
  }
}
