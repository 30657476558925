import { Link } from '@mui/material';
import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import DocumentationIcon from '@/assets/icons/documentation.svg?react';
import CalculatorIcon from '@/assets/icons/calculator.svg?react';
import ChartIcon from '@/assets/icons/chart.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import MonitoringIcon from '@/assets/icons/monitoring.svg?react';
import { useTracking } from '@/hooks/useTracking';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { useGetDocumentation } from '@/api/documentation/documentation.hooks';
import { IconWithHover } from './components/IconWithHover/IconWithHover';
import { EAdminPaths, ERoutes } from '@/types/routes';
import {
  StyledContainer,
  StyledLogoWrapper,
  StyledTopNavBarWrapper,
  StyledAdminPanelIcon,
  StyledBottomNavWrapper,
  StyledSettingsPanelWrapper,
} from '@/layouts/MainLayout/components/SideBar/SideBar.styled';

const SideBar = () => {
  const { logOut } = useNewAuth();
  const { trackEvent } = useTracking();
  const { shouldDisplayDocumentation, shouldDisplayAdminPanel } = usePermissionsManager();
  const { fetchDocumentation } = useGetDocumentation();

  const handleWhitepaperClick = () => {
    trackEvent('Whitepaper Clicked', {});
    fetchDocumentation(undefined, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <StyledContainer>
      <StyledLogoWrapper>
        <Link href={`${ERoutes.MONITORING}/custom`} underline="none">
          <img src={AgioLogo} alt="Agio logo" />
        </Link>
      </StyledLogoWrapper>

      <StyledTopNavBarWrapper>
        <IconWithHover variant="link" href={ERoutes.RATINGS} Icon={ChartIcon} tooltip="Ratings" />
        <IconWithHover
          variant="link"
          href={`${ERoutes.MONITORING}/custom`}
          Icon={MonitoringIcon}
          tooltip="Monitoring"
        />
        <IconWithHover variant="link" href={ERoutes.RISK_SIMULATOR} Icon={CalculatorIcon} tooltip="Risk simulator" />
        {shouldDisplayDocumentation && (
          <IconWithHover
            variant="button"
            onClick={handleWhitepaperClick}
            Icon={DocumentationIcon}
            tooltip="Documentation"
          />
        )}
      </StyledTopNavBarWrapper>
      <StyledBottomNavWrapper>
        <StyledSettingsPanelWrapper>
          <IconWithHover variant="link" href={ERoutes.SETTINGS} Icon={SettingsIcon} tooltip="Settings" />

          {shouldDisplayAdminPanel && (
            <Link href={`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`}>
              <StyledAdminPanelIcon />
            </Link>
          )}
        </StyledSettingsPanelWrapper>

        <IconWithHover variant="button" onClick={logOut} Icon={LogoutIcon} tooltip="Logout" />
      </StyledBottomNavWrapper>
    </StyledContainer>
  );
};

export default SideBar;
