import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 47px;
  margin-top: 20px;
  margin-bottom: 45px;
`;

export const StyledWrapperInput = styled.div`
  width: 253px;
  height: 30px;
  margin-top: 15px;
`;

export const StyledChartsWrapper = styled.div`
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
  width: 100%;
`;

export const StyledTextWrapper = styled.div`
  color: #f7f7f7;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  overflow: auto;
`;

export const StyledWrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0;
`;
