import { useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';
import { INITIAL_PAGE_SIZE } from '@/constants/pagination';
import { useGetUserList } from '@/api/adminUser/adminUser.hooks';
import { useDebounce } from '@/hooks/useDebounce';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import AdminPanelUserCounter from '@/components/molecules/AdminPanelUserCounter/AdminPanelUserCounter';
import { UserList } from '@/components/molecules/AdminPanelWrapperUserList/UserList';
import { IGetUserListRequest } from '@/api/adminUser/adminUser.types';

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60vw;
`;

const StyledFiltersWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;

const StyledBaseTextField = styled(BaseTextField)`
  width: 200px;
`;

const StyledDatePicker = styled(DatePicker)`
  background: #1b1b1e;
  width: 200px;

  & input.MuiInputBase-input.MuiOutlinedInput-input,
  button.MuiButtonBase-root.MuiIconButton-root,
  .MuiFormLabel-root.MuiInputLabel-root {
    color: #ffffff8a;
  }

  .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #5d51b8;
  }
`;

const AdminPanelWrapperUserList = () => {
  const [cohortInputValue, setCohortInputValue] = useState('');
  const [cohortPayload, setCohortPayload] = useState('');
  const [selectedLoginDate, setSelectedLoginDate] = useState<Dayjs | null>(null);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(INITIAL_PAGE_SIZE);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const selectedLoginDateTimestamp = useMemo(() => {
    return selectedLoginDate?.format('YYYY-MM-DD');
  }, [selectedLoginDate]);

  const adminUserListRequestPayload: Partial<IGetUserListRequest> = useMemo(() => {
    return {
      page,
      size,
      ...(cohortPayload.length > 0 ? { cohort: cohortPayload } : undefined),
      ...(selectedLoginDateTimestamp ? { login: selectedLoginDateTimestamp } : undefined),
    };
  }, [page, size, cohortPayload, selectedLoginDateTimestamp]);

  const { isLoading, userList, error, refetch } = useGetUserList(adminUserListRequestPayload);

  const totalUsers = useMemo(() => {
    return userList?.total || 0;
  }, [userList]);

  const toggleCreateUserModal = () => {
    setCreateModalOpen(!isCreateModalOpen);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage + 1);
  };

  const handlePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSize(+event.target.value);
    setPage(1);
  };

  const updateCohortPayload = (cohort: string) => {
    setCohortPayload(cohort);
  };

  const debouncedRequest = useDebounce(() => {
    updateCohortPayload(cohortInputValue);
  });

  const handleCohortChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCohortInputValue(target.value);
    debouncedRequest();
  };

  const handleLoginDatePickerChange = (newValue: unknown) => {
    if (dayjs.isDayjs(newValue)) {
      setSelectedLoginDate(newValue);
    }
  };

  return (
    <>
      <CounterWrapper>
        <AdminPanelUserCounter count={totalUsers} label="Total Users" />
        <AdminPanelUserCounter count={totalUsers} label="Active Users" />
        <AdminPanelUserCounter count={0} label="Suspended Users" />
      </CounterWrapper>

      <StyledFiltersWrapper>
        <StyledBaseTextField name="cohort" label="Cohort" value={cohortInputValue} onChange={handleCohortChange} />
        <StyledDatePicker
          name="select-login-date"
          format="YYYY-MM-DD"
          label="Login date"
          maxDate={dayjs(new Date())}
          value={selectedLoginDate}
          onChange={handleLoginDatePickerChange}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
          }}
          onAccept={(newDate) => {
            if (newDate === null) {
              setSelectedLoginDate(newDate);
            }
          }}
        />
      </StyledFiltersWrapper>

      <UserList
        userList={userList?.items || []}
        size={size}
        handlePageSize={handlePageSize}
        page={page}
        handlePageChange={handlePageChange}
        totalUsers={totalUsers}
        userListError={error}
        isLoadingUserList={isLoading}
        isCreateModalOpen={isCreateModalOpen}
        toggleCreateUserModal={toggleCreateUserModal}
        refetchUserList={refetch}
      />
    </>
  );
};
export default AdminPanelWrapperUserList;
