import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { closestCenter, DndContext, DragEndEvent, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { isArrayOfStrings } from '@/helpers/typeGuards';
import { CustomPointerSensor } from '@/helpers/dragAndDrop';
import { SortableChip } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/components/SortableChip/SortableChip';
import { StyledDragArea } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/components/AutocompleteTags/AutocompleteTags.styled';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';

interface IAutocompleteTagsProps {
  values: unknown[];
  formMethods: UseFormReturn<IMonitoringForm>;
}

export const AutocompleteTags = ({ values, formMethods }: IAutocompleteTagsProps) => {
  const sensors = useSensors(useSensor(CustomPointerSensor));

  const { entities } = formMethods.watch();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over !== null && typeof active.id === 'string' && typeof over.id === 'string') {
      if (active.id !== over.id) {
        const oldIndex = entities.indexOf(active.id);
        const newIndex = entities.indexOf(over.id);
        const reorderedOptions = arrayMove(entities, oldIndex, newIndex);

        formMethods.setValue('entities', reorderedOptions);
      }
    }
  };

  const handleDeleteSelectedOption = (optionToDelete: string) => {
    const filteredEntitiesList = entities.filter((entity) => entity !== optionToDelete);
    formMethods.setValue('entities', filteredEntitiesList);
  };

  if (isArrayOfStrings(values)) {
    return (
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={values} strategy={rectSortingStrategy}>
          <StyledDragArea>
            {values.map((option) => (
              <SortableChip key={option} id={option} label={option} onDelete={handleDeleteSelectedOption} />
            ))}
          </StyledDragArea>
        </SortableContext>
      </DndContext>
    );
  }

  return null;
};
