import styled from '@emotion/styled';

export const StyledDragArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
  width: 100%;
  height: 100%;
`;
