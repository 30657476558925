import styled from '@emotion/styled';
import Button from '@/components/atoms/Button/Button';
import Select from '@/components/molecules/Select/Select';

export const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  --stroke-color: #fff;
`;

export const StyledSelect = styled(Select)`
  width: 300px;
  height: 46px;
`;

export const StyledActionElementsWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
