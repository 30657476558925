import { PointerSensor } from '@dnd-kit/core';

export class CustomPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent }: any) => {
        const target = nativeEvent.target as HTMLElement;

        // Check if clicked element is a button with data-ignore-dnd attribute
        if (target.tagName === 'BUTTON' || target.closest('[data-ignore-dnd]')) {
          return false; // Deactivate drag&drop
        }
        return true; // Activate drag&drop
      },
    },
  ];
}
