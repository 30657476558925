import { RiskZoneKey, TRiskZone } from '@/types/riskZone';

export const adjustRiskZoneChartPoint = (riskZone: TRiskZone): number | null => {
  switch (riskZone) {
    case 'critical':
      return 3;
    case 'high':
      return 2;
    case 'moderate':
      return 1;
    case 'no_risk':
      return 0;
    default:
      return null;
  }
};

export const adjustRiskZoneChartColor = (riskZone: number): string => {
  switch (riskZone) {
    case 3:
      return '#C00000';
    case 2:
      return '#F60102';
    case 1:
      return '#FAC002';
    default:
      return '#4EA72E';
  }
};

export const adjustRiskZoneChartLabel = (riskZone: number | null): string => {
  switch (riskZone) {
    case 3:
      return 'critical';
    case 2:
      return 'high';
    case 1:
      return 'moderate';
    case 0:
      return 'no risk';
    default:
      return '';
  }
};

export const adjustRiskZoneColor = (severity: RiskZoneKey): string => {
  switch (severity) {
    case 'critical':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'moderate':
      return '#FAC002';
    default:
      return '#4EA72E';
  }
};
