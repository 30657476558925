import { Typography } from '@mui/material';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { RiskInfoRow } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/RiskInfoRow';
import { RiskZoneBarsIcon } from '@/components/RiskZoneBarsIcon/RiskZoneBarsIcon';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const ShortTermRisk = ({
  riskZone,
  tag,
  onChainActivity,
  adjustedBalance,
  flowDynamics,
}: Pick<IEntityDetailsProps, 'riskZone' | 'tag' | 'onChainActivity' | 'adjustedBalance' | 'flowDynamics'>) => {
  return (
    <>
      <Typography color="white.100" fontSize="14px">
        Short Term Risk
      </Typography>
      <RiskInfoRow
        tooltip={
          <p>
            Represents the overall risk level derived from the combined analysis of on-chain activity and adjusted
            balances. This gives a single aggregated view of short-term risk.
          </p>
        }
        title="Risk Zone"
      >
        <RiskZoneBarsIcon riskZone={riskZone} />
      </RiskInfoRow>

      <RiskInfoRow
        tooltip={
          <p>
            Tags contextualize anomalies by pointing to specific causes, such as balance anomalies or irregular flow
            activity.
          </p>
        }
        title="Tag"
      >
        <Typography color="#ffffff80" fontSize="14px">
          {tag}
        </Typography>
      </RiskInfoRow>

      <RiskInfoRow
        tooltip={
          <p>
            Tracks the volume and velocity of on-chain transactions, identifying unusual surges or drops that deviate
            from historical patterns.
          </p>
        }
        title="On Chain Activity"
      >
        <AlertSeverityBarsIcon severity={onChainActivity} />
      </RiskInfoRow>

      <RiskInfoRow
        tooltip={<p>Evaluates net balance trends to identify anomalies in balance changes over time.</p>}
        title="Adjusted Balance"
      >
        <AlertSeverityBarsIcon severity={adjustedBalance} />
      </RiskInfoRow>

      <RiskInfoRow
        tooltip={<p>Measures the dominance of inflows vs. outflows to highlight on-chain activity trends.</p>}
        title="Flow Dynamics"
      >
        <Typography color="#ffffff80" fontSize="14px">
          {flowDynamics}
        </Typography>
      </RiskInfoRow>
    </>
  );
};
