import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useQuery } from '@tanstack/react-query';
import { ENTITY_IDS_KEY } from '@/api/constants/queryKeys';
import { handleCatch } from '@/api/helpers';
import { IEntity } from '@/types/entities';

export const useGetUniqueEntitiesList = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const { entityIdsApi } = useApi();

  const {
    data: entitiesList,
    error,
    isLoading,
  } = useQuery<IEntity[], Error>({
    queryKey: [ENTITY_IDS_KEY, 'GET_ALIVE'],
    queryFn: () => {
      return entityIdsApi.getUniqueEntitiesList().catch((error) => {
        return handleCatch(error, `load the entities list`);
      });
    },
    enabled: isAuthenticated,
  });

  return {
    entitiesList,
    error,
    isLoading,
  };
};
