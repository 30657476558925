import { adjustRiskZoneChartColor } from '@/helpers/riskZoneMethods';

export const useAdjustRiskZoneChartZones = ({
  allRiskZones,
  withShade = true,
}: {
  allRiskZones: number[];
  withShade?: boolean;
}) => {
  const highestRiskZone = Math.max(...allRiskZones);
  const lowestRiskZone = Math.min(...allRiskZones);

  const adjustZonesColors = (minSeverity: number, maxSeverity: number) => {
    const totalArrLength = maxSeverity - minSeverity + 1;
    const minColor = adjustRiskZoneChartColor(minSeverity);
    const maxColor = adjustRiskZoneChartColor(maxSeverity);
    let lineStops;
    let areaStops;

    if (totalArrLength < 2 || totalArrLength > 4) {
      return [
        {
          color: adjustRiskZoneChartColor(lowestRiskZone),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [0, `${maxColor}47`],
              [0.8956, `${maxColor}00`],
            ],
          },
        },
      ];
    }

    if (totalArrLength === 2) {
      lineStops = [
        [0.3, maxColor],
        [1, minColor],
      ];

      areaStops = [
        [0.1, `${maxColor}47`],
        [1, `${minColor}00`],
      ];
    }

    if (totalArrLength === 3) {
      lineStops = [
        [0, maxColor],
        [0.5, adjustRiskZoneChartColor(maxSeverity - 1)],
        [1, minColor],
      ];

      areaStops = [
        [0.3, `${maxColor}47`],
        [0.5, `${adjustRiskZoneChartColor(maxSeverity - 1)}24`],
        [1, `${minColor}00`],
      ];
    }

    if (totalArrLength === 4) {
      lineStops = [
        [0, maxColor],
        [0.4, adjustRiskZoneChartColor(maxSeverity - 1)],
        [0.6, adjustRiskZoneChartColor(minSeverity + 1)],
        [1, minColor],
      ];

      areaStops = [
        [0.2, `${maxColor}47`],
        [0.5, `${adjustRiskZoneChartColor(maxSeverity - 1)}36`],
        [0.8, `${adjustRiskZoneChartColor(maxSeverity - 2)}12`],
        [1, `${minColor}00`],
      ];
    }

    const linearGradient = {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1,
    };

    const line = {
      linearGradient,
      stops: lineStops,
    };

    const area = {
      linearGradient,
      stops: areaStops,
    };

    return [
      {
        color: line,
        fillColor: withShade ? area : {},
      },
    ];
  };

  const zones = adjustZonesColors(lowestRiskZone, highestRiskZone);

  return { zones };
};
