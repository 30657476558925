import React, { useEffect, useState } from 'react';
import { StyledImg } from '@/components/ExchangeLogo/ExchangeLogo.styled';

interface ExchangeLogoProps {
  companyName: string;
  height?: string;
}

const ExchangeLogo = ({ companyName, height = '60px' }: ExchangeLogoProps) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  const imagePath = `https://cdn.agioratings.io/icons/${companyName.replace('.', '_').replace('-', '_')}.png`;
  const placeholderPath = 'https://cdn.agioratings.io/icons/logo.png';

  useEffect(() => {
    setImageLoaded(true);
  }, [companyName]);

  const handleError = () => {
    console.warn(`Missing logo: ${companyName}`);
    setImageLoaded(false);
  };

  return (
    <>
      {imageLoaded && <StyledImg src={imagePath} alt={companyName} onError={handleError} height={height} />}

      {!imageLoaded && <StyledImg src={placeholderPath} alt={companyName} onError={handleError} height={height} />}
    </>
  );
};

export default ExchangeLogo;
