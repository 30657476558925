import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ICreateUserRequest,
  IEditUserAsAdminRequest,
  IGetUserListRequest,
  IGetUserListResponse,
} from '@/api/adminUser/adminUser.types';
import { ADMIN_USER_KEY, USER_LIST_KEY } from '@/api/constants/queryKeys';
import { IUserDto, IUserId } from '@/types/user';
import { AxiosError } from 'axios';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { handleCatch } from '@/api/helpers';

export const useGetUserList = (payload: Partial<IGetUserListRequest>) => {
  const { adminUserApi } = useApi();

  const keys = [payload.page, payload.size, payload.cohort, payload.login].filter((e) => e).join(',');

  const {
    data: userList,
    error,
    isLoading,
    refetch,
  } = useQuery<IGetUserListResponse, Error>({
    queryKey: [USER_LIST_KEY, 'GET_USER_LIST', keys],
    queryFn: () => {
      return adminUserApi.getUserList(payload).catch((error) => {
        return handleCatch(error, `load the user list`);
      });
    },
    staleTime: 0,
  });

  return {
    userList,
    error,
    isLoading,
    refetch,
  };
};

export const useGetUserById = ({ id }: IUserId) => {
  const { adminUserApi } = useApi();

  const {
    data: fetchedUser,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IUserId, Error, IUserDto>({
    queryKey: [ADMIN_USER_KEY, 'GET', 'BY_ID', id],
    meta: { userId: id },
    queryFn: ({ meta }) => {
      if (meta && meta.userId && typeof meta.userId === 'string') {
        return adminUserApi
          .getUserById(meta.userId)
          .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user data`)));
      }
      throw new Error('userId should be a meta string');
    },
  });

  return {
    fetchedUser,
    isLoading,
    isSuccess,
    error,
  };
};

export const useCreateUser = () => {
  const { adminUserApi } = useApi();

  const {
    isPending,
    error,
    mutate: createUser,
  } = useMutation<IUserDto, Error, ICreateUserRequest>({
    mutationKey: [ADMIN_USER_KEY, 'CREATE'],
    mutationFn: (payload: ICreateUserRequest) => {
      return adminUserApi.createUser(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred.');
        }
      });
    },
  });

  return {
    isLoading: isPending,
    error,
    createUser,
  };
};

export const useEditUserAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: editUser } = useMutation<IUserDto, Error, IEditUserAsAdminRequest>({
    mutationKey: [ADMIN_USER_KEY, 'EDIT'],
    mutationFn: (payload: IEditUserAsAdminRequest) => {
      return adminUserApi.editUser(payload).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error('An unexpected error occurred. Make sure that email has been used is unique and try again.');
        }
      });
    },
  });

  return {
    error,
    editUser,
  };
};

export const useDeleteUserAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: deleteUser } = useMutation<void, Error, IUserId>({
    mutationKey: [ADMIN_USER_KEY, 'DELETE'],
    mutationFn: ({ id }: IUserId) => {
      return adminUserApi.deleteUser(id).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error("An unexpected error occurred and we couldn't delete the user.");
        }
      });
    },
  });

  return {
    error,
    deleteUser,
  };
};

export const useResetUserPasswordAsAdmin = () => {
  const { adminUserApi } = useApi();

  const { error, mutate: resetUserPassword } = useMutation<IUserDto, Error, IUserId>({
    mutationKey: [ADMIN_USER_KEY, 'RESET_USER_PASSWORD'],
    mutationFn: ({ id }: IUserId) => {
      return adminUserApi.resetUserPassword(id).catch((reason) => {
        if (reason instanceof AxiosError && reason.response) {
          const errorMessage = reason.response.data.detail || reason.response.data.detail[0].msg;
          throw new Error(errorMessage);
        } else {
          throw new Error("An unexpected error occurred and we couldn't reset password");
        }
      });
    },
  });

  return {
    error,
    resetUserPassword,
  };
};
