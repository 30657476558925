import styled from '@emotion/styled';

export const GroupHeader = styled.div`
  position: sticky;
  top: -8px;
  z-index: 1;
  padding: 4px 10px;
  background-color: #5d51b8;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.23px;
  color: #fff;
  text-transform: uppercase;
`;

export const GroupItems = styled.ul`
  padding: 0;
`;
