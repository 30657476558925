import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { find, isObject } from 'lodash';
import { formatToUSD, amountToNumber, formatDateToUK } from '@/helpers/helpers';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import { mapChainData } from '@/helpers/mapChartsData';
import { severityLevels } from '@/constants/InitialState/severityLevel';
import { useGetAlertByIdByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import WrapperCharts from '@/components/molecules/Charts/WrapperCharts';
import Loader from '@/components/atoms/Loader/Loader';
import AdminPanelUpdatesList from '@/components/molecules/AdminPanelUpdatesList/AdminPanelUpdatesList';
import AdminButton from '@/components/atoms/AdminButton/AdminButton';
import Text from '@/components/atoms/Text/Text';
import {
  StyledChartsWrapper,
  StyledContainer,
  StyledTextWrapper,
  StyledWrapperInput,
  StyledWrapperButton,
} from './AdminPanelAlertDetails.styled';
import { EAdminPaths, ERoutes } from '@/types/routes';

const AlertDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { fetchedAlert, isLoading, error } = useGetAlertByIdByAdmin({ alertId: id || '' });

  if (isLoading) {
    return <Loader />;
  }

  if (error) return <Typography color="white.100">Error getting alert: {error.message}</Typography>;

  if (!fetchedAlert) return <Typography color="white.100">There is no alert data to display.</Typography>;

  const formattedData = fetchedAlert.chainData ? mapChainData(fetchedAlert.chainData) : [];
  const formattedNetFlowData = mapChainData(fetchedAlert.netflowData);
  const formattedTotalFlowData = mapChainData(fetchedAlert.totalFlowData);

  const anomalyChainData = formattedData.sort((a, b) => a.x - b.x)[formattedData.length - 1];

  const severityLabel = find(severityLevels, { value: fetchedAlert.severity });

  const handleCancel = () => navigate(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`);

  return (
    <>
      <StyledContainer>
        <div>
          <Text fontSize="14px">Exchange</Text>
          <StyledWrapperInput>
            <BaseTextField name="exchange" borderColor="#fff" value={fetchedAlert.metadata?.exchange || ''} disabled />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">Timestamp</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="timestamp"
              borderColor="#fff"
              value={formatDateToUK(fetchedAlert.timestamp)}
              disabled
            />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">Variable</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="variable"
              borderColor="#fff"
              value={mapMetadataType(fetchedAlert.metadata?.type)}
              disabled
            />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">z-Score</Text>
          <StyledWrapperInput>
            <BaseTextField name="z_score" borderColor="#fff" value={fetchedAlert.zScore.toString()} disabled />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">%Change</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="change_percentage"
              borderColor="#fff"
              value={fetchedAlert.changePercentage?.toString()}
              disabled
            />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">Change Amount</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="change_amount"
              borderColor="#fff"
              value={fetchedAlert.changeAmount ? formatToUSD(amountToNumber(fetchedAlert.changeAmount.toString())) : ''}
              disabled
            />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">Entity type</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="entity_type"
              borderColor="#fff"
              value={fetchedAlert.metadata?.entityType || ''}
              disabled
            />
          </StyledWrapperInput>
        </div>
        <div>
          <Text fontSize="14px">Severity</Text>
          <StyledWrapperInput>
            <BaseTextField
              name="severity"
              borderColor="#fff"
              value={isObject(severityLabel) ? severityLabel?.name : ''}
              disabled
            />
          </StyledWrapperInput>
        </div>
      </StyledContainer>

      <div>
        <Text fontSize="14px">Insight</Text>
        <StyledTextWrapper dangerouslySetInnerHTML={{ __html: fetchedAlert.insight || '' }} />
      </div>

      <StyledChartsWrapper>
        <WrapperCharts
          alertId={fetchedAlert.id}
          anomalyChainData={anomalyChainData}
          layout="row"
          chartData={formattedData}
          netflowData={formattedNetFlowData}
          totalflowData={formattedTotalFlowData}
          alertType={fetchedAlert.metadata?.type}
        />
      </StyledChartsWrapper>

      <AdminPanelUpdatesList
        updates={fetchedAlert.updates}
        alertID={fetchedAlert.id}
        anomalyChainData={anomalyChainData}
      />

      <StyledWrapperButton>
        <AdminButton onClick={handleCancel}>Go back</AdminButton>
      </StyledWrapperButton>
    </>
  );
};

export default AlertDetails;
