import React from 'react';
import { Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFullExchangeTypeName } from '@/helpers/exchangeNames';
import Button from '@/components/atoms/Button/Button';
import {
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAccordions/MonitorEntitiesAccordions.styled';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';
import { IEntitiesMonitoringFormProps } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';

interface IMonitorEntitiesAccordionsProps extends Pick<IEntitiesMonitoringFormProps, 'formMethods'> {
  sortedEntitiesList: IExchange[];
  listOfEntityTypesToDisplay: string[];
}

export const MonitorEntitiesAccordions = ({
  formMethods,
  sortedEntitiesList,
  listOfEntityTypesToDisplay,
}: IMonitorEntitiesAccordionsProps) => {
  const { entities } = formMethods.watch();

  const handleAccordionButtonClick = (exchange: IExchange) => {
    const isAlreadyAdded = entities.some((entity) => entity === exchange.label);

    if (isAlreadyAdded) {
      const filteredOutExchange = entities.filter((entity) => entity !== exchange.label);
      formMethods.setValue('entities', filteredOutExchange);
      return;
    }

    const newExchanges = [...entities, exchange.label];

    formMethods.setValue('entities', newExchanges);
  };

  const generateButtonOptions = (type: string) =>
    sortedEntitiesList.map((exchange) => {
      const isSelected = entities.some((entity) => entity === exchange.label);

      if (exchange.type === type) {
        return (
          <Button
            key={`${exchange.value}-${exchange.type}`}
            variant={isSelected ? 'contained' : 'outlined'}
            fitContentWidth
            onClick={() => {
              handleAccordionButtonClick(exchange);
            }}
          >
            {exchange.label}
          </Button>
        );
      }
    });

  return (
    <div>
      {listOfEntityTypesToDisplay.map((type) => {
        return (
          <Accordion key={type}>
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
              aria-controls={`${type}-content`}
              id={`${type}-header`}
            >
              {getFullExchangeTypeName(type).toUpperCase()}
            </StyledAccordionSummary>

            <StyledAccordionDetails>{generateButtonOptions(type)}</StyledAccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
