import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { useQuery } from '@tanstack/react-query';
import { ADMIN_ALERTS_KEY } from '@/api/constants/queryKeys';
import { handleCatch } from '@/api/helpers';
import { IAlertId, IExtendedAlertDto } from '@/types/alert';
import { IGetAlertsListRequest, IGetAlertsListResponse } from '@/api/adminAlerts/adminAlerts.types';

export const useGetAlertsListByAdmin = (payload: Partial<IGetAlertsListRequest>) => {
  const { adminAlertsApi } = useApi();

  const keys = [payload.page, payload.size].filter((e) => e).join(',');

  const {
    data: alertsList,
    error,
    isLoading,
  } = useQuery<IGetAlertsListResponse, Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_ALERT_LIST', keys],
    queryFn: () => {
      return adminAlertsApi.getAlertsList(payload).catch((error) => {
        return handleCatch(error, `load the alert list`);
      });
    },
    staleTime: 0,
  });

  return {
    alertsList,
    error,
    isLoading,
  };
};

export const useGetAlertByIdByAdmin = ({ alertId }: IAlertId) => {
  const { adminAlertsApi } = useApi();

  const {
    data: fetchedAlert,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IExtendedAlertDto, Error>({
    queryKey: [ADMIN_ALERTS_KEY, 'GET_ALERT', alertId],
    meta: { alertId: alertId },
    queryFn: ({ meta }) => {
      if (meta && meta.alertId && typeof meta.alertId === 'string') {
        return adminAlertsApi.getAlertById(meta.alertId).catch((reason) => {
          return handleCatch(reason, `load the alert`);
        });
      }
      throw new Error('alertId should be a meta string');
    },
  });
  return {
    fetchedAlert,
    isLoading,
    isSuccess,
    error,
  };
};
