import { adjustRiskZoneColor } from '@/helpers/riskZoneMethods';
import { riskZones } from '@/constants/InitialState/riskZone';
import { StyledRiskZoneIcon, StyledRiskZoneWrapper } from './RiskZoneBarsIcon.styled';
import { TRiskZone } from '@/types/riskZone';

interface IRiskZoneBarsIconProps {
  riskZone: TRiskZone;
}

export const RiskZoneBarsIcon = ({ riskZone }: IRiskZoneBarsIconProps) => {
  if (riskZone === null) return null;

  const label = riskZones.find(({ value }) => value === riskZone);

  if (!label) return null;

  const riskZoneColor = adjustRiskZoneColor(riskZone);
  const isNoRisk = riskZone === 'no_risk';

  return (
    <StyledRiskZoneWrapper color={riskZoneColor}>
      {label.name}
      {!isNoRisk && <StyledRiskZoneIcon color={riskZoneColor} className={riskZone} />}
    </StyledRiskZoneWrapper>
  );
};
