import React from 'react';
import Loader from '@/components/atoms/Loader/Loader';
import { MonitorEntitiesAutocomplete } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/MonitorEntitiesAutocomplete';
import { MonitorEntitiesAccordions } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAccordions/MonitorEntitiesAccordions';
import { StyledWrapper } from './EntitiesMonitoringFormContent.styled';
import { IEntitiesMonitoringFormProps } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm';

export const EntitiesMonitoringFormContent = ({
  isGetMonitoringSettingsLoading,
  formMethods,
  sortedEntitiesByType,
  listOfEntityTypesToDisplay,
}: IEntitiesMonitoringFormProps) => {
  if (isGetMonitoringSettingsLoading) {
    return <Loader />;
  }

  return (
    <StyledWrapper>
      <MonitorEntitiesAutocomplete formMethods={formMethods} sortedEntitiesList={sortedEntitiesByType} />

      <MonitorEntitiesAccordions
        formMethods={formMethods}
        sortedEntitiesList={sortedEntitiesByType}
        listOfEntityTypesToDisplay={listOfEntityTypesToDisplay}
      />
    </StyledWrapper>
  );
};
