import styled from '@emotion/styled';
import { AccordionDetails, AccordionSummary } from '@mui/material';

export const StyledAccordionSummary = styled(AccordionSummary)`
  background-color: #222;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.23px;
  color: #fff;
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  background-color: #222222;
`;
