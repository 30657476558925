import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectComponent({ redirectUrl }: { redirectUrl: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(redirectUrl);
  });
  return null;
}

export default RedirectComponent;
