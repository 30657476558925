import { PropsWithChildren, ReactNode } from 'react';
import { Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import HelpIcon from '@/assets/icons/help.svg';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledRowDataWrapper,
  StyledTooltipContent,
} from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';

interface IRiskInfoRowProps {
  tooltip: ReactNode;
  title: string;
}

export const RiskInfoRow = ({ children, tooltip, title }: PropsWithChildren<IRiskInfoRowProps>) => {
  return (
    <StyledRowDataWrapper>
      <div>
        <Tooltip arrow TransitionComponent={Zoom} placement="left" title={tooltip}>
          <StyledTooltipContent>
            <img src={HelpIcon} alt="Help icon" width="16px" height="16px" />
            <Typography color="#ffffff80" fontSize="14px">
              {title}
            </Typography>
          </StyledTooltipContent>
        </Tooltip>
      </div>
      {children}
    </StyledRowDataWrapper>
  );
};
