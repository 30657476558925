import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';
import { FULLY_CENTERED_COLUMNS, FULLY_RIGHT_COLUMNS } from '@/constants/gridColumnConfigurations';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { useTracking } from '@/hooks/useTracking';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { PdChangeComponent } from '@/components/PdChangeComponent/PdChangeComponent';
import { StyledCompanyWrapper, StyledRatingsDataGrid } from '@/components/StyledSharedComponents/StyledTables';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { formatFloatToPercent } from '@/helpers/helpers';
import { ERoutes } from '@/types/routes';

interface IRatingsTableProps {
  ratings: IStatisticalRatingEntity[];
}

const PD_CHANGE_OPTIONS: Partial<GridColDef> = {
  width: 80,
  sortable: false,
};

export const RatingsTable = ({ ratings }: IRatingsTableProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();

  const handleExchangeClick = ({ entity }: Pick<IStatisticalRatingEntity, 'entity'>) => {
    trackEvent('Stats ratings - selected', { entity });
    navigate(`${ERoutes.RATINGS}/${entity}`);
  };

  const columnsConfiguration: GridColDef[] = [
    {
      field: 'index',
      headerName: '#',
      ...FULLY_RIGHT_COLUMNS,
      width: 40,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <Typography>{params.row.idx}</Typography>;
      },
    },
    {
      field: 'exchange',
      headerName: 'Company',
      width: 270,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledCompanyWrapper onClick={() => handleExchangeClick(params.row)}>
            <ExchangeLogo companyName={params.row.entity} height="50px" />
            {getFullExchangeName(params.row.entity)}
          </StyledCompanyWrapper>
        );
      },
    },
    {
      field: 'pd',
      headerName: '1 YR PD',
      ...FULLY_CENTERED_COLUMNS,
      width: 80,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        const lastPD = params.row.data[0];
        if (lastPD)
          return (
            <Typography fontSize="14px" fontWeight={600} width="100%" align="center">
              {formatFloatToPercent(lastPD.value)}
            </Typography>
          );
      },
    },
    {
      field: 'oneWeek',
      headerName: '1W',
      ...FULLY_CENTERED_COLUMNS,
      ...PD_CHANGE_OPTIONS,
      renderCell: (params: GridRenderCellParams) => {
        return <PdChangeComponent specificPdChange={params.row.pdChanges?.oneWeek} />;
      },
    },
    {
      field: 'oneMonth',
      headerName: '1M',
      ...FULLY_CENTERED_COLUMNS,
      ...PD_CHANGE_OPTIONS,
      renderCell: (params: GridRenderCellParams) => {
        return <PdChangeComponent specificPdChange={params.row.pdChanges?.oneMonth} />;
      },
    },
    {
      field: 'sixMonths',
      headerName: '6M',
      ...FULLY_CENTERED_COLUMNS,
      ...PD_CHANGE_OPTIONS,
      renderCell: (params: GridRenderCellParams) => {
        return <PdChangeComponent specificPdChange={params.row.pdChanges?.sixMonths} />;
      },
    },
  ];

  const rows = ratings.filter((i) => i.pdChanges && i.data.length).map((row, index) => ({ ...row, idx: index + 1 }));

  const getRowId = (row: any) => row.entity;

  return (
    <StyledRatingsDataGrid
      rows={rows}
      getRowId={getRowId}
      rowHeight={40}
      columns={columnsConfiguration}
      disableColumnMenu
      hideFooter
    />
  );
};
