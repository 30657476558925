import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import {
  IGetMonitoringRequest,
  IGetMonitoringDetailsResponse,
  IMonitoredEntity,
  IMonitoringApi,
} from '@/api/monitoring/monitoring.types';

export class MonitoringApi implements IMonitoringApi {
  private getBaseUrl(): string {
    return ApiEndpoints.MONITORING;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getMonitoring({ entityType, allHighAndExtreme }: IGetMonitoringRequest) {
    const params = { entityTypes: entityType, allHighAndExtreme: allHighAndExtreme };

    return this.httpClient.get<IMonitoredEntity[]>(this.getBaseUrl(), { params });
  }

  getMonitoringDetails(entityId: string) {
    return this.httpClient.get<IGetMonitoringDetailsResponse>(`${this.getBaseUrl()}${entityId}/`);
  }
}
