import { SeverityLevelKey } from '@/types/severityLevel';
import { SEVERITY_LEVELS } from '@/constants/InitialState/severityLevel';
import { ChainKey } from '@/types/chain';
import { CHAINS } from '@/constants/chain';

export function isOfType<T>(valueToCheck: any, validValues: T[]): valueToCheck is T {
  return validValues.includes(valueToCheck);
}

export const isArrayOfStrings = (variable: unknown): variable is string[] => {
  return Array.isArray(variable) && variable.every((item) => typeof item === 'string');
};

export function isObjectWithKey<T extends object>(value: unknown, key: keyof T): value is T {
  return typeof value === 'object' && value !== null && key in value;
}

export const isSeverityLevelKey = (severityKey: string): severityKey is SeverityLevelKey => {
  return SEVERITY_LEVELS.includes(severityKey as SeverityLevelKey);
};

export const isChainKey = (chainKey: string): chainKey is ChainKey => {
  return CHAINS.includes(chainKey as ChainKey);
};
