import React from 'react';
import { Controller } from 'react-hook-form';
import {
  handleGroupBy,
  handleSelectOption,
  handleOnChange,
} from '@/views/MonitoringSettingsView/utils/autocompleteHelpers';
import { AutocompleteGroup } from '@/components/AutocompleteGroup/AutocompleteGroup';
import { AutocompleteOptionWithCheckbox } from '@/components/AutocompleteOptionWithCheckbox/AutocompleteOptionWithCheckbox';
import { EntitiesToEmailNotificationInput } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/EntitiesToEmailNotification/components/EntitiesToEmailNotificationInput/EntitiesToEmailNotificationInput';
import {
  StyledAutocomplete,
  StyledPaper,
} from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/components/EntitiesToEmailNotification/EntitiesToEmailNotification.styled';
import { IEntitiesEmailNotificationsFormProps } from '@/views/MonitoringSettingsView/components/EntitiesEmailNotificationsForm/EntitiesEmailNotificationsForm';

export const EntitiesToEmailNotification = ({
  formMethods,
  sortedEntitiesByType,
}: Pick<IEntitiesEmailNotificationsFormProps, 'formMethods' | 'sortedEntitiesByType'>) => {
  return (
    <Controller
      render={({ field: { value, onBlur, onChange, ref }, formState: { errors } }) => {
        return (
          <StyledAutocomplete
            multiple
            id="entities-list-to-notificate"
            disableCloseOnSelect
            disablePortal
            options={sortedEntitiesByType}
            groupBy={handleGroupBy}
            renderInput={(params) => (
              <EntitiesToEmailNotificationInput params={params} errors={errors} fieldRef={ref} />
            )}
            value={value}
            PaperComponent={StyledPaper}
            renderGroup={(params) => <AutocompleteGroup params={params} />}
            renderOption={(props, option, { selected }) => (
              <AutocompleteOptionWithCheckbox props={props} option={option} selected={selected} />
            )}
            isOptionEqualToValue={handleSelectOption}
            onChange={(event, newValues) => handleOnChange(newValues, onChange)}
            onBlur={onBlur}
          />
        );
      }}
      name="emailNotifications.selectedEntities"
      control={formMethods.control}
    />
  );
};
