import styled from '@emotion/styled';
import HelpIcon from '@/assets/icons/help.svg?react';
import Tooltip from '../atoms/Tooltip/Tooltip';

const VariableNameContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
`;

interface VariableNameProps {
  title: string;
  hint: string;
}

const VariableName = ({ title, hint }: VariableNameProps) => {
  return (
    <VariableNameContainer>
      <Tooltip title={hint}>
        <div>
          <HelpIcon style={{ width: '15px', height: '15px', marginRight: '5px ' }} />
        </div>
      </Tooltip>
      {title}
    </VariableNameContainer>
  );
};

export default VariableName;
