import { IPermissionPreference, EPermissions } from '@/types/permissions';

export const permissionsInitialState: IPermissionPreference[] = [
  { name: 'Admin', value: EPermissions.ADMIN, enabled: false },
  { name: 'Owner', value: EPermissions.OWNER, enabled: false },
  { name: 'Risks', value: EPermissions.RISKS, enabled: false },
  { name: 'Alerts', value: EPermissions.ALERTS, enabled: false },
  { name: 'Documentation', value: EPermissions.WHITE_PAPER, enabled: false },
];

export const betaUserPermissions: IPermissionPreference[] = [
  { name: 'Admin', value: EPermissions.ADMIN, enabled: false },
  { name: 'Owner', value: EPermissions.OWNER, enabled: false },
  { name: 'Risks', value: EPermissions.RISKS, enabled: true },
  { name: 'Alerts', value: EPermissions.ALERTS, enabled: true },
  { name: 'Documentation', value: EPermissions.WHITE_PAPER, enabled: true },
];
