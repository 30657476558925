import { Typography } from '@mui/material';
import { formatToUSD } from '@/helpers/helpers';
import { RiskInfoRow } from '@/views/EntityMonitoringView/components/EntityDetails/components/ShortTermRisk/RiskInfoRow';
import { StyledRowDataWrapper } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails.styled';
import { IEntityDetailsProps } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';

export const SignificantData = ({
  balance,
  outflow,
  inflow,
  totalFlow,
  netflow,
}: Pick<IEntityDetailsProps, 'balance' | 'outflow' | 'inflow' | 'totalFlow' | 'netflow'>) => {
  return (
    <>
      <Typography color="white.100" fontSize="14px">
        On-Chain Data
      </Typography>

      <RiskInfoRow
        tooltip={
          <>
            <p>
              Observable Balance represents the on-chain balances tracked using our continuously evolving wallet
              monitoring methodology.
            </p>
            <p>
              While we strive to capture the most comprehensive view possible, we cannot guarantee 100% wallet coverage.
            </p>
          </>
        }
        title="Observable Balance"
      >
        <Typography color="white.100" fontSize="16px" align="right">
          {balance ? formatToUSD(balance, true) : ''}
        </Typography>
      </RiskInfoRow>

      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24H Inflows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {inflow ? formatToUSD(inflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>

      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24H Outflows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {outflow ? formatToUSD(outflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>

      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24H Totalflows
        </Typography>
        <Typography color="white.100" fontSize="16px" align="right">
          {totalFlow ? formatToUSD(totalFlow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>

      <StyledRowDataWrapper>
        <Typography color="#ffffff80" fontSize="14px">
          24H Netflows
        </Typography>
        <Typography color={netflow && netflow > 0 ? 'green' : 'red'} fontSize="16px" align="right">
          {netflow ? formatToUSD(netflow, true) : ''}
        </Typography>
      </StyledRowDataWrapper>
    </>
  );
};
