import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Alert } from '@mui/material';
import Button from '@/components/atoms/Button/Button';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import loginLogo from '@/assets/svg/login-logo.svg';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { ELocalStorage } from '@/types/storage';
import { ERoutes } from '@/types/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 343px;
  height: 698px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #2e2e31;
  padding: 19px 104px 32px;
`;

const WrapperInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 345px;
  margin-bottom: 24px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 41px;
`;

const LogoImage = styled.img`
  width: 200px;
  height: auto;
`;

const StyledText = styled.span`
  color: #666;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
`;

const StyledTextEmail = styled.span`
  color: #5d51b8;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: 0.56px;
`;

const WrapperTexts = styled.div`
  height: 164px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
`;

const LoginForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [uiLoginError, setUiLoginError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { logIn, loginError } = useNewAuth();

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const userCredentials = { username: username, password: password };

    logIn(userCredentials, () => {
      const redirectUrl = localStorage.getItem(ELocalStorage.REDIRECT_URL);

      if (redirectUrl) {
        navigate(redirectUrl);
        localStorage.removeItem(ELocalStorage.REDIRECT_URL);
      } else {
        navigate(`${ERoutes.MONITORING}/custom`);
      }
    });
  };

  useEffect(() => {
    if (loginError) {
      setUiLoginError(loginError.message);
    }
  }, [loginError]);

  return (
    <Container>
      <LogoContainer>
        <LogoImage src={loginLogo} alt="Agio rating logo" />
      </LogoContainer>
      <form noValidate autoComplete="off" onSubmit={handleOnSubmit}>
        <WrapperInputs>
          <BaseTextField
            id="username"
            label="Email"
            value={username}
            onChange={(e) => {
              uiLoginError && setUiLoginError(null);
              setUsername(e.target.value);
            }}
          />
          <BaseTextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => {
              uiLoginError && setUiLoginError(null);
              setPassword(e.target.value);
            }}
          />
        </WrapperInputs>
        {uiLoginError && <Alert severity="error">{uiLoginError}</Alert>}
        <Button type="submit" variant="contained">
          Login
        </Button>

        <WrapperTexts>
          <StyledText>Need some help? Contact us at</StyledText>
          <StyledTextEmail>contact@agioratings.io</StyledTextEmail>
        </WrapperTexts>
      </form>
    </Container>
  );
};

export default LoginForm;
