import { SeverityLevelKey, TSeverity } from '@/types/severityLevel';

export const adjustSeverityColor = (severity: SeverityLevelKey): string => {
  switch (severity) {
    case 'extreme':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'medium':
      return '#FAC002';
    case 'low':
      return '#FEFF01';
    default:
      return '#4EA72E';
  }
};

export const adjustSeverityLabel = (severity: TSeverity) => {
  if (severity === 'medium') {
    return 'Moderate';
  }

  return severity;
};
