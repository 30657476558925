import { useNavigate, useParams } from 'react-router-dom';
import { AppBar, Link, Toolbar, Typography } from '@mui/material';
import Zoom from '@mui/material/Zoom';
import styled from '@emotion/styled';
import { find } from 'lodash';
import InfoIcon from '@/assets/icons/info.svg';
import ArrowLeft from '@/assets/icons/arrow-left.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import { monitoringDashboardOptions } from '@/constants/monitoringDashboardTypes';
import { HEADER_HEIGHT } from '@/layouts/MainLayout/constants';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useTracking } from '@/hooks/useTracking';
import Button from '@/components/atoms/Button/Button';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import {
  StyledActionElementsWrapper,
  StyledButton,
  StyledSelect,
} from '@/components/molecules/Headers/MonitoringHeader.styled';
import { ERoutes } from '@/types/routes';
import { isMonitoringDashboardTypeKey } from '@/types/dashboardTypes';

const StyledHeader = styled(AppBar)`
  height: ${HEADER_HEIGHT};
  width: 100%;
  background-color: #101014;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
`;

interface IMonitoringHeaderProps {
  shouldDisplayBackButton?: boolean;
  shouldDisplaySettingsButton?: boolean;
  shouldDisplaySelectDashboardButton?: boolean;
  shouldDisplaySubtitle?: boolean;
}

const MonitoringHeader = ({
  shouldDisplayBackButton = false,
  shouldDisplaySettingsButton = false,
  shouldDisplaySelectDashboardButton = false,
  shouldDisplaySubtitle = false,
}: IMonitoringHeaderProps) => {
  const { shouldDisplayAlerts } = usePermissionsManager();
  const { trackEvent, trackFilterUsage } = useTracking();
  const navigate = useNavigate();
  const { dashboardType } = useParams();

  const handleOnChangeMonitoringDashboard = (value: string) => {
    navigate(`${ERoutes.MONITORING}/${value}`);
    trackFilterUsage('Monitoring Dashboard Filter', value);
  };

  const generateSubtitle = () => {
    if (dashboardType && isMonitoringDashboardTypeKey(dashboardType)) {
      const foundDashboard = find(monitoringDashboardOptions, {
        value: dashboardType,
      });

      return foundDashboard?.label || '';
    }
  };

  if (!shouldDisplayAlerts) {
    return (
      <StyledHeader position="static">
        <Toolbar>
          <Typography variant="h4">Risk Monitoring</Typography>
        </Toolbar>
      </StyledHeader>
    );
  }

  return (
    <StyledHeader position="static">
      <Toolbar sx={{ justifyContent: 'space-between', overflow: 'auto', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '16px', paddingRight: '16px' }}>
          {shouldDisplayBackButton && (
            <Button
              onClick={() => navigate(-1)}
              skipTypography
              fitContentWidth
              disableRipple
              blockHoverBehavior
              sx={{ paddingInline: '5px' }}
            >
              <ArrowLeft />
            </Button>
          )}

          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Typography variant="h4" style={{ flexGrow: 1 }} noWrap>
              Risk Monitoring
            </Typography>
            <Tooltip
              style={{ alignSelf: 'start' }}
              arrow
              title={
                <>
                  <p>
                    <strong>The Risk Monitoring Tool</strong> delivers hourly insights with always-on anomaly detection
                    and customizable dashboards.
                  </p>
                  <p>
                    Focused on short-term risk, it provides instant updates and enhanced visibility to help you
                    proactively manage your risk landscape.
                  </p>
                </>
              }
            >
              <img src={InfoIcon} alt="Info icon" />
            </Tooltip>
            {shouldDisplaySubtitle && dashboardType && (
              <Typography color="white.70" variant="h4" fontSize="30px" fontStyle="italic" noWrap>
                {generateSubtitle()}
              </Typography>
            )}
          </div>
        </div>

        <StyledActionElementsWrapper>
          {shouldDisplaySelectDashboardButton && (
            <StyledSelect
              options={monitoringDashboardOptions}
              onChange={handleOnChangeMonitoringDashboard}
              value="Switch Dashboards"
              placeholder="Switch Dashboards"
              clearVisible={false}
            />
          )}

          {shouldDisplaySettingsButton && (
            <Tooltip arrow TransitionComponent={Zoom} placement="left" title="Monitoring settings">
              <Link
                href={ERoutes.MONITORING_SETTINGS}
                underline="none"
                onClick={() => trackEvent('Monitoring - click settings', {})}
              >
                <StyledButton variant="outlined" color="tertiary" skipTypography>
                  <SettingsIcon />
                  Customize
                </StyledButton>
              </Link>
            </Tooltip>
          )}
        </StyledActionElementsWrapper>
      </Toolbar>
    </StyledHeader>
  );
};

export default MonitoringHeader;
