import React from 'react';
import { AutocompleteRenderGroupParams } from '@mui/material';
import { getFullExchangeTypeName } from '@/helpers/exchangeNames';
import { GroupHeader, GroupItems } from '@/components/AutocompleteGroup/AutocompleteGroup.styled';

interface IAutocompleteGroupProps {
  params: AutocompleteRenderGroupParams;
}

export const AutocompleteGroup = ({ params }: IAutocompleteGroupProps) => {
  return (
    <li key={params.key}>
      <GroupHeader>{getFullExchangeTypeName(params.group)}</GroupHeader>
      <GroupItems>{params.children}</GroupItems>
    </li>
  );
};
