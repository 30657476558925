import { MONITORING_DASHBOARD_TYPES } from '@/constants/monitoringDashboardTypes';
import { ISpecificOption } from '@/types/select';

export type MonitoringDashboardTypeKey = (typeof MONITORING_DASHBOARD_TYPES)[number];

export type TMonitoringDashboardOption = ISpecificOption<MonitoringDashboardTypeKey>;

export const isMonitoringDashboardTypeKey = (
  dashboardTypeKey: string,
): dashboardTypeKey is MonitoringDashboardTypeKey => {
  return MONITORING_DASHBOARD_TYPES.includes(dashboardTypeKey as MonitoringDashboardTypeKey);
};
