import { ChartData, ChartPoint } from '@/types/chartPoint';
import { adjustRiskZoneChartPoint } from '@/helpers/riskZoneMethods';
import { IRiskZoneDTO } from '@/types/riskZone';

interface DataItem {
  timestamp: string;
  value: number | null;
}

export const mapChainData = (data: Array<DataItem>): ChartData => {
  let mapped: ChartData = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: item.value,
      };
    });

  return mapped;
};

export const mapRiskZoneChartData = (data: Array<IRiskZoneDTO>): ChartData => {
  let mapped: ChartPoint[] = [];
  mapped = data
    .filter((item) => item?.value)
    .map((item) => {
      return {
        x: new Date(item.timestamp).getTime(),
        y: adjustRiskZoneChartPoint(item.value),
      };
    });

  return mapped;
};
