import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
`;

export const StyledTitle = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;

export const StyledScrollWrapper = styled.div`
  overflow: auto;
`;

export const StyledChartWrapper = styled.div`
  min-width: 600px;
`;
