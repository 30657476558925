import { useFlowChartsManager } from '@/hooks/useFlowChartsManager';
import AnomalyTimeLineChart from '@/components/molecules/Charts/AnomalyTimeLineChart';
import ColumnChart from '@/components/molecules/Charts/ColumnChart';
import Select from '@/components/molecules/Select/Select';
import {
  StyledSpan,
  ChartsContainer,
  StyledSingleChartWrapper,
} from '@/components/molecules/Charts/WrapperCharts.styled';
import { ChartData, ChartPoint } from '@/types/chartPoint';

interface WrapperChartsProps {
  alertId: string;
  chartData: ChartData;
  anomalyChainData?: ChartPoint;
  layout?: 'row' | 'column';
  netflowData: ChartData;
  inflowData?: ChartData;
  outflowData?: ChartData;
  totalflowData: ChartData;
  hourlyNetflowData?: ChartData;
  hourlyInflowData?: ChartData;
  hourlyOutflowData?: ChartData;
  hourlyTotalflowData?: ChartData;
  alertType?: string | null;
  setAreChartsRendered?: (val: boolean) => void;
}

const WrapperCharts = ({
  alertId,
  anomalyChainData,
  layout = 'column',
  chartData,
  netflowData,
  inflowData,
  outflowData,
  totalflowData,
  hourlyNetflowData,
  hourlyInflowData,
  hourlyOutflowData,
  hourlyTotalflowData,
  alertType,
  setAreChartsRendered,
}: WrapperChartsProps) => {
  const {
    shouldRenderSelect,
    filteredFlowDataChartOptions,
    handleSelectedFlowDataChange,
    selectedFlowDataChart,
    shouldRenderFlowsChart,
    generatedFlowData,
    generatedTitle,
    isHourlyChart,
  } = useFlowChartsManager({
    netflowData,
    inflowData,
    outflowData,
    totalflowData,
    hourlyNetflowData,
    hourlyInflowData,
    hourlyOutflowData,
    hourlyTotalflowData,
    alertType,
  });

  if (!chartData.length && !shouldRenderFlowsChart) {
    setAreChartsRendered && setAreChartsRendered(false);
    return null;
  }

  return (
    <ChartsContainer layout={layout}>
      {!!chartData.length && (
        <StyledSingleChartWrapper layout={layout}>
          <StyledSpan layout={layout}>Timeseries</StyledSpan>
          <AnomalyTimeLineChart alertId={alertId} anomalyChainData={anomalyChainData} chartData={chartData} />
        </StyledSingleChartWrapper>
      )}

      <StyledSingleChartWrapper layout={layout}>
        {shouldRenderSelect && (
          <Select
            options={filteredFlowDataChartOptions}
            onChange={handleSelectedFlowDataChange}
            value={selectedFlowDataChart || ''}
            clearVisible={false}
          />
        )}
        {shouldRenderFlowsChart && (
          <ColumnChart
            key={selectedFlowDataChart}
            flowData={generatedFlowData}
            isTotalFlow={selectedFlowDataChart === 'totalflow' || selectedFlowDataChart === 'totalflow-hourly'}
            title={generatedTitle}
            isHourlyChart={isHourlyChart}
          />
        )}
      </StyledSingleChartWrapper>
    </ChartsContainer>
  );
};

export default WrapperCharts;
