import { RefObject } from 'react';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import 'highcharts/modules/exporting';
import 'highcharts/modules/offline-exporting';
import 'highcharts/modules/export-data';
import { generateExportingSetupForColumnChart } from '@/constants/chartOptions';
import { getListOfNumbers } from '@/helpers/helpers';
import { TTransformedDataFrequency } from '@/views/RiskSimulatorPortfolioView/utils/types';

const NUMBER_OF_DEFAULTS = 'NUMBER OF DEFAULTS';

interface INumberOfDefaultsChartProps {
  chartRef: RefObject<HighchartsReactRefObject>;
  defaultsFrequency: TTransformedDataFrequency[];
}

export const NumberOfDefaultsChart = ({ defaultsFrequency, chartRef }: INumberOfDefaultsChartProps) => {
  const categories = getListOfNumbers(defaultsFrequency.length);

  const chartData = () => {
    return defaultsFrequency.map((dataEl) => {
      const numberOfDefaults = dataEl[0];
      const frequency = dataEl[1];

      return [+numberOfDefaults, frequency];
    });
  };

  const EXPORTING_OPTIONS = generateExportingSetupForColumnChart(NUMBER_OF_DEFAULTS);

  const chartOptions = {
    chart: {
      type: 'column',
      backgroundColor: null,
    },
    title: {
      text: undefined,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories,
      title: {
        text: 'Number of Defaults',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        style: {
          color: '#FFFFFF',
        },
      },
    },
    yAxis: {
      min: 0,
      max: !!defaultsFrequency.length && 100,
      title: {
        text: 'Frequency',
        style: {
          color: '#FFFFFF',
        },
      },
      labels: {
        format: '{text}%',
        style: {
          color: '#FFFFFF',
        },
      },
      gridLineColor: 'transparent',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      format: '<span style="color:{color}">\u25CF</span> ' + '{series.name}: <b>{y}</b>%<br/>',
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          format: '{y}%',
        },
      },
    },
    series: [
      {
        name: 'Frequency',
        data: chartData(),
        color: {
          linearGradient: { x0: 0, y0: 0, x1: 1, y1: 1 },
          stops: [
            [0, '#82789D'],
            [1, '#322759'],
          ],
        },
        borderColor: '#543E9D',
      },
    ],
    exporting: {
      ...EXPORTING_OPTIONS.exporting,
    },
  };

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
};
