import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IAdminAlertsApi, IGetAlertsListRequest, IGetAlertsListResponse } from '@/api/adminAlerts/adminAlerts.types';
import { IExtendedAlertDto } from '@/types/alert';

export class AdminAlertsApi implements IAdminAlertsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ADMIN_ALERTS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getAlertsList({ page, size }: Partial<IGetAlertsListRequest>) {
    const params = {
      page,
      size,
    };

    return this.httpClient.get<IGetAlertsListResponse>(this.getBaseUrl(), { params });
  }

  getAlertById(alertId: string) {
    return this.httpClient.get<IExtendedAlertDto>(`${this.getBaseUrl()}${alertId}/`);
  }
}
