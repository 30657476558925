import { RiskSimulatorProvider } from '@/views/RiskSimulatorPortfolioView/utils/RiskSimulator.context';
import { Simulator } from '@/views/RiskSimulatorPortfolioView/components/Simulator/Simulator';
import { ResultOfRiskSimulation } from './components/ResultOfRiskSimulation/ResultOfRiskSimulation';
import { StyledContainer, StyledSeparator } from '@/views/RiskSimulatorPortfolioView/RiskSimulatorPortfolioView.styled';
import { IStatisticalRatingEntity } from '@/types/ratings';
import { useRef } from 'react';

export interface IRiskSimulatorPortfolioViewProps {
  fetchedRatings: IStatisticalRatingEntity[];
}

export const RiskSimulatorPortfolioView = ({ fetchedRatings }: IRiskSimulatorPortfolioViewProps) => {
  const resultsRef = useRef<HTMLDivElement>(null);

  const handleFormSubmit = () => {
    resultsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <StyledContainer>
      <RiskSimulatorProvider fetchedRatings={fetchedRatings}>
        <Simulator onFormSubmit={handleFormSubmit} />

        <StyledSeparator />

        <div ref={resultsRef}>
          <ResultOfRiskSimulation />
        </div>
      </RiskSimulatorProvider>
    </StyledContainer>
  );
};
