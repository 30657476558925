import { Calculations } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/Calculations/Calculations';
import { SimulationParameters } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/SimulationParameters/SimulationParameters';
import { StyledContainer } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView.styled';
import { useRef } from 'react';

export const GeneratedResultView = () => {
  const ref = useRef<HTMLDivElement>(null);

  setTimeout(() => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 250);

  return (
    <StyledContainer>
      <Calculations />

      <div ref={ref} />
      <SimulationParameters />
    </StyledContainer>
  );
};
