import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { isObjectWithKey } from '@/helpers/typeGuards';

interface IAutocompleteOptionWithCheckboxProps {
  props: React.HTMLAttributes<HTMLLIElement>;
  option: unknown;
  selected: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const AutocompleteOptionWithCheckbox = ({ props, option, selected }: IAutocompleteOptionWithCheckboxProps) => {
  if (isObjectWithKey(option, 'label')) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { key, ...optionProps } = props;
    return (
      <li key={key} {...optionProps}>
        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
        {option.label}
      </li>
    );
  }

  return null;
};
