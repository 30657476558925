import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  StyledChip,
  StyledDeleteButton,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/MonitorEntitiesAutocomplete/components/SortableChip/SortableChip.styled';

interface ISortableChipProps {
  id: string;
  label: string;
  onDelete: (optionToDelete: string) => void;
}

export const SortableChip = ({ id, label, onDelete }: ISortableChipProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(id);
  };

  return (
    <StyledChip
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      variant="outlined"
      label={label}
      deleteIcon={
        <StyledDeleteButton data-ignore-dnd>
          <CancelIcon />
        </StyledDeleteButton>
      }
      onDelete={handleDelete}
      isDragging={isDragging}
      transform={transform ? CSS.Transform.toString(transform) : undefined}
      transition={transition}
    />
  );
};
