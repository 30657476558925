import styled from '@emotion/styled';
import RiskZoneIcon from '@/assets/icons/riskZoneBars.svg?react';

export const StyledRiskZoneWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  line-height: normal;
  color: ${({ color }) => color};
`;

export const StyledRiskZoneIcon = styled(RiskZoneIcon)<{ color: string }>`
  &.moderate {
    > rect:nth-of-type(3) {
      fill: ${({ color }) => color};
    }
  }

  &.high {
    > rect:nth-of-type(3),
    > rect:nth-of-type(2) {
      fill: ${({ color }) => color};
    }
  }

  &.critical {
    > rect {
      fill: ${({ color }) => color};
    }
  }
`;
