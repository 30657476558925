import styled from '@emotion/styled';
import BaseTextField from '@/components/atoms/BaseTextField/BaseTextField';
import Text from '@/components/atoms/Text/Text';

interface WrapperInputProps {
  width?: string;
}

export const StyledWrapperInput = styled.div<WrapperInputProps>`
  width: ${(props) => props.width || '253px'};
  margin: 20px 0px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

export const StyledInput = styled(BaseTextField)`
  margin-top: 20px;
`;

export const StyledUpdatesContainer = styled.div`
  display: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

export const StyledWrapperButton = styled.div`
  width: 253px;
  height: 30px;
  margin: 40px 0;
`;
