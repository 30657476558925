import styled from '@emotion/styled';
import { Link } from '@mui/material';
import { formatDateToUK } from '@/helpers/helpers';
import { mapMetadataType } from '@/helpers/mapMetadataType';
import Loader from '@/components/atoms/Loader/Loader';
import { IAlertDto } from '@/types/alert';

const AlertContainer = styled.section`
  width: 80%;
  margin-top: 20px;
  min-height: 250px;
`;

const AlertTitle = styled.h2`
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const AlertTable = styled.table`
  width: 100%;
  min-height: 300px;
  border-collapse: collapse;

  th,
  td {
    padding: 10px;
  }
`;

const TableHeaderCell = styled.th`
  background-color: #58585b;
  color: white;
`;

const TableBodyCell = styled.td`
  background-color: #242428;
  color: white;
  text-align: center;
`;

const TableBody = styled.tbody`
  border-color: black;
  position: relative;
`;

const StyledCenteredTableBodyWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

interface IAdminPanelAlertListProps {
  title: string;
  isLoading: boolean;
  isEmptyList: boolean;
  alerts: IAlertDto[];
}

const AdminPanelAlertList = ({ title, alerts, isLoading, isEmptyList }: IAdminPanelAlertListProps) => {
  return (
    <AlertContainer>
      <AlertTitle>{title}</AlertTitle>
      <div style={{ maxHeight: '300px', overflow: 'auto', position: 'relative' }}>
        <AlertTable>
          <thead style={{ position: 'sticky', top: 0 }}>
            <tr>
              <TableHeaderCell>Date</TableHeaderCell>
              <TableHeaderCell>Exchange</TableHeaderCell>
              <TableHeaderCell>Variable</TableHeaderCell>
              <TableHeaderCell>Severity</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </tr>
          </thead>
          <TableBody>
            {isLoading ? (
              <StyledCenteredTableBodyWrapper>
                <Loader />
              </StyledCenteredTableBodyWrapper>
            ) : isEmptyList ? (
              <StyledCenteredTableBodyWrapper>
                <div style={{ color: 'white' }}>There is no alerts to display</div>
              </StyledCenteredTableBodyWrapper>
            ) : (
              alerts.map((alert, index) => (
                <tr key={index}>
                  <TableBodyCell>{formatDateToUK(alert.timestamp)}</TableBodyCell>
                  <TableBodyCell>{alert.metadata?.exchange}</TableBodyCell>
                  <TableBodyCell>{mapMetadataType(alert.metadata?.type)}</TableBodyCell>
                  <TableBodyCell>{alert.zScore}</TableBodyCell>
                  <TableBodyCell>
                    <Link href={`/admin/alerts/${alert.id}`} target="_blank">
                      Manage
                    </Link>
                  </TableBodyCell>
                </tr>
              ))
            )}
          </TableBody>
        </AlertTable>
      </div>
    </AlertContainer>
  );
};

export default AdminPanelAlertList;
