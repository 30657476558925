import { useMemo, useState } from 'react';
import { mapRiskZoneChartData } from '@/helpers/mapChartsData';
import {
  isTrendingRiskOptionKey,
  TrendingRiskOptionKey,
} from '@/views/EntityMonitoringView/components/TrendingRiskChart/utils/types';
import { ChartData } from '@/types/chartPoint';
import { IRiskZoneDTO } from '@/types/riskZone';

interface IUseTrendingRiskChartModeManagerProps {
  lastWeekRiskZoneData: IRiskZoneDTO[];
  lastDayRiskZoneData: IRiskZoneDTO[];
}

export const useTrendingRiskChartModeManager = ({
  lastWeekRiskZoneData,
  lastDayRiskZoneData,
}: IUseTrendingRiskChartModeManagerProps) => {
  const [selectedTrendingRiskChart, setSelectedTrendingRiskChart] = useState<TrendingRiskOptionKey>('lastDay');

  const handleTrendingRiskChartChange = (value: string) => {
    if (isTrendingRiskOptionKey(value)) {
      setSelectedTrendingRiskChart(value);
    } else {
      console.error("The value doesn't below to the trending risk chart options.");
    }
  };

  const weekChartDataset = mapRiskZoneChartData(lastWeekRiskZoneData);
  const dayChartDataset = mapRiskZoneChartData(lastDayRiskZoneData);

  const getChartData = (selectedTrendingRiskChart: TrendingRiskOptionKey): ChartData => {
    switch (selectedTrendingRiskChart) {
      case 'lastWeek':
        return weekChartDataset;
      case 'lastDay':
        return dayChartDataset;
      default:
        return [];
    }
  };

  const isHourlyChart = selectedTrendingRiskChart === 'lastDay';

  const generatedChartData = useMemo(() => getChartData(selectedTrendingRiskChart), [selectedTrendingRiskChart]);

  return { selectedTrendingRiskChart, handleTrendingRiskChartChange, isHourlyChart, generatedChartData };
};
