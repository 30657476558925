import { IMonitoringForm, TAnomaliesEmailNotificationsOption } from '@/views/MonitoringSettingsView/utils/types';

export const defaultMonitoringFormValues: IMonitoringForm = {
  entities: [],
  emailNotifications: {
    notificationsMode: 'all',
    selectedEntities: [],
  },
};

export const ANOMALIES_EMAIL_NOTIFICATIONS_OPTIONS = ['none', 'all', 'custom', 'select'] as const;

export const anomaliesEmailNotificationsOptions: TAnomaliesEmailNotificationsOption[] = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Custom Dashboard',
    value: 'custom',
  },
  {
    label: 'Select entities manually',
    value: 'select',
  },
];
