import { TSeverity } from '@/types/severityLevel';
import { IChainData, IShortChainData } from '@/types/chainData';

export interface IAlertId {
  alertId: string;
}

export interface IAlertEntity {
  displayName: string | null;
  isRated: boolean | null;
}

export interface IExtendedAlertDto {
  id: string;
  timestamp: string;
  zScore: number;
  changePercentage: number | null;
  changeAmount: number | null;
  insight: string | null;
  metadata: IAlertMetadata | null;
  reviewed: boolean | null;
  deleted: boolean | null;
  severity: TSeverity;
  updates: IExtendedAlertUpdateDto[];
  chainData: IChainData[] | null;
  inflowData: IShortChainData[];
  netflowData: IShortChainData[];
  outflowData: IShortChainData[];
  totalFlowData: IShortChainData[];
  hourlyNetflowData: IShortChainData[];
  hourlyInflowData: IShortChainData[];
  hourlyOutflowData: IShortChainData[];
  hourlyTotalFlowData: IShortChainData[];
  latestHourlyChaindata: IChainDataHourly[] | null;
  entity: IAlertEntity | null;
}

export interface IAlertDto {
  id: string;
  timestamp: string;
  metadata: IAlertMetadata | null;
  zScore: number;
  insight: string | null;
  reviewed: boolean | null;
  updates: IAlertUpdateDto[];
  changePercentage: number | null;
  changeAmount: number | null;
  deleted: boolean | null;
  latestHourlyChaindata: IChainDataHourly[] | null;
  entity: IAlertEntity | null;
  severity: TSeverity;
  chainData: IChainData[] | null;
  inflowData: IShortChainData[];
  netflowData: IShortChainData[];
  outflowData: IShortChainData[];
  totalFlowData: IShortChainData[];
}

export interface IAlertUpdateDto {
  id: string;
  collection: string;
}

export interface IExtendedAlertUpdateDto {
  id: string | null;
  title: string;
  timestamp: string;
  details: string;
  deleted: boolean | null;
  showChart: boolean;
  chartType: TChartType;
  chainData: IChainData[];
  inflowData: IShortChainData[];
  netflowData: IShortChainData[];
  outflowData: IShortChainData[];
  totalFlowData: IShortChainData[];
}

export type TChartType = 'totalbalance' | 'all' | null;

export interface IChainMetadata {
  exchange: string | null;
  isAggregate: boolean;
  source: string | null;
  symbol: string;
  type: string;
  units: string | null;
}

export interface IAlertMetadata {
  symbol: string | null;
  type: string | null;
  source: string | null;
  exchange: string | null;
  entityType: string | null;
  method: string | null;
  units: string | null;
  interval: string | null;
}

export interface IChainDataHourly {
  id: string | null;
  metadata: IChainMetadata;
  timestamp: string;
  intervalStart: string | null;
  value: number;
  deleted: boolean | null;
}

export enum AlertMetadataType {
  BALANCE = 'balance',
  INFLOW = 'inflow',
  OUTFLOW = 'outflow',
  TOTAL_FLOW = 'totalflow',
  NETFLOW = 'netflow',
}
