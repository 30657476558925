import { useCallback, useMemo, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { TRiskZone } from '@/types/riskZone';
import { adjustRiskZoneChartPoint } from '@/helpers/riskZoneMethods';

export const useSortingManager = () => {
  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'exampleField', sort: 'asc' }]);

  const updateSortModel = (newModel: GridSortModel) => setSortModel(newModel);

  const sortingRiskZoneModel = sortModel.find((model) => model.field === 'riskZone');

  const isRiskZoneAscendant = useMemo(
    () => !!sortingRiskZoneModel && sortingRiskZoneModel.sort === 'asc',
    [sortingRiskZoneModel],
  );

  const riskZoneComparator = useCallback(
    (v1: TRiskZone, v2: TRiskZone) => {
      const riskZoneCode1 = adjustRiskZoneChartPoint(v1);
      const riskZoneCode2 = adjustRiskZoneChartPoint(v2);

      if (riskZoneCode1 === null && riskZoneCode2 === null) return 0;
      if (riskZoneCode1 === null) return isRiskZoneAscendant ? 1 : -1;
      if (riskZoneCode2 === null) return isRiskZoneAscendant ? -1 : 1;

      return riskZoneCode1 - riskZoneCode2;
    },
    [isRiskZoneAscendant],
  );

  return {
    sortModel,
    updateSortModel,
    riskZoneComparator,
  };
};
