import styled from '@emotion/styled';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  padding: 24px;
  border: 1px solid #2e2e31;
  width: 100%;
  overflow: auto;
`;
