import { IRiskZone } from '@/types/riskZone';

export const riskZones: IRiskZone[] = [
  { name: 'No Risk', value: 'no_risk' },
  { name: 'Moderate', value: 'moderate' },
  { name: 'High', value: 'high' },
  { name: 'Critical', value: 'critical' },
];

export const RISK_ZONES = ['critical', 'high', 'moderate', 'no_risk'] as const;
