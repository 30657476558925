import { ApiEndpoints } from '@/types/routes';
import { IHttpClient } from '@/api/HttpClient';
import { IEntityIdsApi } from '@/api/entityIds/entityIds.types';
import { IEntity } from '@/types/entities';

export class EntityIdsApi implements IEntityIdsApi {
  private getBaseUrl(): string {
    return ApiEndpoints.ENTITY_IDS;
  }

  constructor(private readonly httpClient: IHttpClient) {}

  getUniqueEntitiesList() {
    return this.httpClient.get<IEntity[]>(`${this.getBaseUrl()}alive`);
  }
}
