import { useAlertsManager } from '@/components/molecules/AdminPanelWrapperAlertsList/utils/useAlertsManager';
import { AlertsList } from '@/components/molecules/AdminPanelWrapperAlertsList/AlertsList';

const AdminPanelWrapperAlertsList = () => {
  const {
    pagePublished,
    publishedAlertsSize,
    alertsList,
    isLoadingAlertsList,
    alertsListError,
    totalPublishedAlerts,
    handlePublishedAlertsPageChange,
    handlePublishedAlertsPageSize,
  } = useAlertsManager();

  return (
    <>
      <AlertsList
        alertsList={alertsList?.items || []}
        publishedAlertsSize={publishedAlertsSize}
        handlePublishedAlertsPageSize={handlePublishedAlertsPageSize}
        pagePublished={pagePublished}
        handlePublishedAlertsPageChange={handlePublishedAlertsPageChange}
        totalPublishedAlerts={totalPublishedAlerts}
        alertsListError={alertsListError}
        isLoadingAlertsList={isLoadingAlertsList}
      />
    </>
  );
};

export default AdminPanelWrapperAlertsList;
