import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Typography } from '@mui/material';
import { EntitiesMonitoringFormContent } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/components/EntitiesMonitoringFormContent/EntitiesMonitoringFormContent';
import { StyledContainer } from '@/views/MonitoringSettingsView/components/EntitiesMonitoringForm/EntitiesMonitoringForm.styled';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

export interface IEntitiesMonitoringFormProps {
  listOfEntityTypesToDisplay: string[];
  sortedEntitiesByType: IExchange[];
  formMethods: UseFormReturn<IMonitoringForm>;
  isGetMonitoringSettingsLoading: boolean;
}

export const EntitiesMonitoringForm = (props: IEntitiesMonitoringFormProps) => {
  return (
    <StyledContainer>
      <Typography variant="h2" color="white.100" fontWeight="semibold">
        Entities to monitor on the custom dashboard
      </Typography>

      <EntitiesMonitoringFormContent {...props} />
    </StyledContainer>
  );
};
